import {setContext} from '@apollo/client/link/context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {createUploadLink} from 'apollo-upload-client';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { extractFiles } from 'extract-files';
import { ApolloLink, ApolloClient, InMemoryCache } from "@apollo/client";
import {ACCESS_TOKEN_KEY} from "../context/AuthContext";

const apolloURI = process.env.NODE_ENV === 'development'
    ? "http://localhost:3000/"
    : "https://stori-backend.herokuapp.com"

const authLink = setContext(async (_, {headers}) => {
    const token = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const uploadAndBatchHTTPLink = () => ApolloLink.split(
    operation => extractFiles(operation).files.size > 0,
    createUploadLink({
        uri: apolloURI,
        headers: {
            "keep-alive": "true"
        }
    }),
    new BatchHttpLink({
        uri: apolloURI,
    })
);

export const apolloLink = authLink.concat(uploadAndBatchHTTPLink());

export const client = new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache(),
});
