import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {useAuthContext} from "../context/AuthContext";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {useHistory} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
// @ts-ignore
import Select from 'react-select';
import {SelectOption} from "../Types";
import AdminHeader from "../components/AdminHeader";

interface AuthorData {
  id: number;
  alias: string;
  name: string;
}

interface FetchAuthorData {
  authors: Array<AuthorData>;
}

export const ALL_AUTHORS_FRAGMENT = `
authors {
  id
  alias
  name
}
`;

export const ALL_AUTHORS = gql`
  query FetchAuthors {
    ${ALL_AUTHORS_FRAGMENT}
  }
`;

export function AdminLoginPage () {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const {loginAsAuthor} = useAuthContext();
  const [authorOptions, setAuthorOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | null>(null);
  const {data: authorData} = useQuery<FetchAuthorData>(ALL_AUTHORS);

  React.useEffect(() => {
    if (loggedIn) {
      history.push('/home');
    }
  }, [loggedIn, history]);

  React.useEffect(() => {
    if (authorData) {
      console.log("author data");
      console.log(authorData);
      const options: SelectOption[] = [];
      for (let author of authorData.authors) {
        const label = author.alias + (author.name ? ` (${author.name})` : '');
        options.push({
          value: author.id.toString(),
          label: label,
        });
      }
      setAuthorOptions(options);
    }
  }, [authorData]);

  async function handleLogin() {
    console.log("handle login");
    const authorId = selected!.value;
    try {
      await loginAsAuthor(authorId);
      createToast(`Logging in as ${selected}`);
      setLoggedIn(true);
    } catch (ex) {
      createToast(ex.toString());
      console.log(ex);
      return false;
    }
  }

  function validateForm() {
    return selected;
  }

  function handleSelect(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelected(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Login</h2>
          <hr/>
          <h5>
            You are signed in as an admin, and have the ability to login as an author and make changes on their behalf.
          </h5>
          <Card className="Admin">
            <Card.Header>Login As Author</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}>
                {authorOptions &&
                <div>
                  <Form.Label>
                    Author
                  </Form.Label>
                  <Select
                    value={selected}
                    onChange={handleSelect}
                    options={authorOptions}
                  />
                  <br></br>
                </div>}
                <LoginButton
                  text="Login"
                  type="submit"
                  disabled={!validateForm()}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
