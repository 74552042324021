import React from 'react';
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const FormDate = ({
  label,
  value,
  onChange,
  minDate,
}: {
  label: string,
  value: Date | null;
  onChange: (event: Date | null) => void;
  minDate?: Date;
}) => {
  return (
    <Form.Group style={{
      marginTop: '10px',
      marginBottom: '10px',
    }}>
    <Form.Label>
      {label}
    </Form.Label>
    <DatePicker
      selected={value}
      onChange={onChange}
      minDate={minDate}
    />
    </Form.Group>
  );
};
