import React from 'react';
import {
  ApolloProvider,
} from '@apollo/client';
import {client} from "./utils/Apollo";
import {AuthContextProvider, useAuthContext} from './context/AuthContext';
import {Route, useLocation, Redirect, useHistory} from "react-router-dom";
import { Switch } from "react-router";
import { LoginPage } from './pages/Login'
import { ResetPasswordPage } from './pages/ResetPassword'
import { CreateAccountPage } from "./pages/CreateAccount";
import { RegisterAuthorPage } from './pages/RegisterAuthor'
import { AdminLoginPage } from './pages/AdminLogin'
import { AdminRoyaltyPage } from './pages/AdminRoyalty'
import { AdminCarouselPage } from './pages/AdminCarousel'
import { AdminGenrePage } from './pages/AdminGenre'
import { AdminCouponPage } from './pages/AdminCoupon'
import { AdminStatsPage } from './pages/AdminStats'
import { AdminUsersPage } from './pages/AdminUsers'
import { NoMatchPage } from './pages/NoMatch'
import { HomePage } from './pages/Home'
import { PaymentPage } from './pages/Payment'
import { AnalyticsPage } from './pages/Analytics'
import { ContentPage } from './pages/Content'
import { CouponsPage } from './pages/Coupons'
import { BonusContentPage } from './pages/BonusContent'
import './App.css';
import '../node_modules/react-pro-sidebar/dist/css/styles.css';

// must be logged in and an author to view dashboard
const DashboardRoute = ({ component: Component, ...rest }: any) => {
  const location = useLocation();
  const {user, author} = useAuthContext();
  let comp;

  console.log("dashboard route");
  console.log(user);
  if (user == null) {
    comp = <Redirect to={{ pathname: "/login", state: { from: location } }} />
  } else if (author) {
    comp = <Component />
  } else if (user.isAdmin) {
    comp = <Redirect to={{ pathname: "/admin-login"}} />
  } else {
    comp = <Redirect to={{ pathname: "/register-author"}} />
  }

  return (
    <Route {...rest}>
      {comp}
    </Route>
  );
};

// protects routing non admin pages
const AdminRoute = ({ component: Component, ...rest }: any) => {
  const location = useLocation();
  const {user} = useAuthContext();
  let comp;

  console.log("admin route");
  console.log(user);
  if (user == null) {
    comp = <Redirect to={{ pathname: "/login", state: { from: location } }} />
  } else if (user.isAdmin) {
    comp = <Component />
  } else {
    comp = <Redirect to={{ pathname: "/home"}} />
  }

  return (
    <Route {...rest}>
      {comp}
    </Route>
  );
};

// protects routing pages for users who are not authors (register author)
const NonAuthorRoute = ({ ...rest }: any) => {
  const location = useLocation();
  const {user} = useAuthContext();
  let comp;

  console.log("non author route");
  console.log(user);

  if (user == null) {
    comp = <Redirect to={{ pathname: "/login", state: { from: location } }} />
  } else if (!user.isAuthor) {
    comp = <RegisterAuthorPage />
  } else {
    comp = <Redirect to={{ pathname: "/home"}} />
  }

  return (
    <Route {...rest}>
      {comp}
    </Route>
  );
};

const Logout = () => {
  const history = useHistory();
  const {logout} = useAuthContext();
  logout();
  history.push('/login');
  return (
    <div/>
  );
}

const Dashboard = () => (
  <div>
    <Switch>
      <DashboardRoute path="/home" component={HomePage} />
      <DashboardRoute path="/payment" component={PaymentPage} />
      <DashboardRoute path="/content" component={ContentPage} />
      <DashboardRoute path="/analytics" component={AnalyticsPage} />
      {/*<DashboardRoute path="/coupons" component={CouponsPage} />*/}
      <DashboardRoute path="/bonus" component={BonusContentPage} />
      <AdminRoute path="/admin-login" component={AdminLoginPage} />
      <AdminRoute path="/admin-royalty" component={AdminRoyaltyPage} />
      <AdminRoute path="/admin-carousel" component={AdminCarouselPage} />
      <AdminRoute path="/admin-genre" component={AdminGenrePage} />
      <AdminRoute path="/admin-coupon" component={AdminCouponPage} />
      <AdminRoute path="/admin-stats" component={AdminStatsPage} />
      <AdminRoute path="/admin-users" component={AdminUsersPage} />
      {/*<AdminRoute path="/analytics" component={AnalyticsPage} />*/}
      <AdminRoute path="/coupons" component={CouponsPage} />
      <NonAuthorRoute path="/register-author" />
      <Route path="/logout"><Logout /></Route>
      <Route component={NoMatchPage}/>
    </Switch>
  </div>
)

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthContextProvider>
        <div className="App">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={LoginPage} />
            <Route path="/create-account" component={CreateAccountPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route component={Dashboard}/>
          </Switch>
        </div>
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default App;
