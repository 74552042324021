import React, {useState, useMemo} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import {Route, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';
// @ts-ignore
import Select from 'react-select';
import {useAuthContext} from "../context/AuthContext";
import AdminHeader from "../components/AdminHeader";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import FormInput from "../components/FormInput";
import FormUploadImage from "../components/FormUploadImage";
import LoginButton from "../components/LoginButton";
import FormSelect from "../components/FormSelect";
import {SelectOption} from '../Types';
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {ImageType} from 'react-images-uploading';

const CHAPTER_QUERY = `
id
chapterIndex
seasonIndex
name
beanPrice
`

const BOOK_QUERY = `
id
name
author {
  name
}
chapters {
  ${CHAPTER_QUERY}
}
genres {
  id
  displayName
}
description
shortDescription
coverImageUrl
viewCount
followerCount
readerCount
matureThemes
unlockableByReading
`;

const SEASON_QUERY = `
id
seasonIndex
name
description
seasonPassBeanPrice
disableSeasonPass
`

interface BookAuthor {
  id: number;
  name: string;
}

interface ChapterData {
  id: number;
  chapterIndex: number;
  seasonIndex: number;
  name: string;
  beanPrice: number;
}

interface GenreData {
  id: number;
  displayName: string;
}

interface BookData {
  id: number;
  coverImageUrl?: string;
  name: string;
  description: string;
  shortDescription: string;
  author: BookAuthor;
  chapters: Array<ChapterData>;
  genres: Array<GenreData>;
  viewCount: number;
  followerCount: number;
  readerCount: number;
  matureThemes: boolean;
  unlockableByReading: boolean;
}

interface AuthorData {
  id: string;
  name: string;
  description: string;
  twitterHandle?: string;
  isFollowing: boolean;
  books: Array<BookData>;
  profilePicUrl: string;
}

interface FetchAuthorData {
  authorById: AuthorData;
}

interface GenreData {
  id: number;
  label: string;
  displayName: string;
}

interface FetchGenreData {
  genres: Array<GenreData>;
}

interface BookUpdateInput {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  genres: Array<number>;
  matureThemes: boolean;
  unlockableByReading: boolean;
  file: File | null | undefined;
}

interface ChapterUpdateInput {
  id: number;
  name: string;
  baseBeanPrice: number;
}

// TODO: maybe we should use a sql query to fetch all the book/chapter information for better efficiency
export const FETCH_AUTHOR = gql`
  query FetchAuthorById($id: String!) {
    authorById(id: $id) {
      id
      name
      description
      twitterHandle
      isFollowing
      books {
        ${BOOK_QUERY}
      }
      profilePicUrl
    }
  }
`;

export const ALL_GENRES_FRAGMENT = `
genres {
  id
  label
  displayName
}
`;

export const ALL_GENRES = gql`
  query FetchGenres {
    ${ALL_GENRES_FRAGMENT}
  }
`;

const UPDATE_BOOK = gql`
  mutation UpdateBook($data: BookUpdateInput!) {
    updateBook(data: $data) {
      ${BOOK_QUERY}
    }
  }
`;

const UPDATE_CHAPTER = gql`
  mutation UpdateChapter($data: ChapterUpdateInput!) {
    updateChapter(data: $data) {
      ${CHAPTER_QUERY}
    }
  }
`;

const UPDATE_SEASON = gql`
  mutation UpdateSeason($data: SeasonUpdateInput!) {
    updateSeason(data: $data) {
      ${SEASON_QUERY}
    }
  }
`;

interface SeasonUpdateInput {
  id: number;
  name: string;
  description: string;
  seasonPassBeanPrice: number;
  disableSeasonPass: boolean;
  freeChapters: number | null;
  constantChapterPricing: number | null;
}

interface SeasonData {
  id: number;
  seasonIndex: number;
  name: string;
  description: string;
  seasonPassBeanPrice: number;
  disableSeasonPass: boolean;
}

interface FetchSeasonData {
  allSeasons: Array<SeasonData>;
}

export const FETCH_SEASONS = gql`
  query allSeasons($bookId: Int!) {
    allSeasons(bookId: $bookId) {
      ${SEASON_QUERY}
    }
  }
`;

// TODO: prob simpler if we don't do routing per book
export function ContentPage() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [authorId, setAuthorId] = useState<number | null>(null);
  const [selected, setSelected] = useState<SelectOption | null>(null);
  const [bookData, setBookData] = useState<Map<string, BookData> | null>(null);
  const [selectOptions, setSelectOptions] = useState<SelectOption[] | null>(null);
  const {author} = useAuthContext();
  const [fetchAuthor, {data, error}] = useLazyQuery<FetchAuthorData>(FETCH_AUTHOR, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const {data: genreData} = useQuery<FetchGenreData>(ALL_GENRES);

  React.useEffect(() => {
    if (author) {
      setAuthorId(author.id);
    }
  }, [author]);

  React.useEffect(() => {
    if (authorId) {
      fetchAuthor({
        variables: {id: authorId!.toString()},
      });
    }
  }, [fetchAuthor, authorId]);

  React.useEffect(() => {
    if (data) {
      console.log('------------received analytic data');
      console.log(data);
      const bookOptions: SelectOption[] = [];
      for (let book of data.authorById.books) {
        console.log(book);
        bookOptions.push({
          value: book.id.toString(),
          label: book.name,
        });
      }
      setSelectOptions(bookOptions);
      setBookData(new Map(data.authorById.books.map(book => [book.id.toString(), book])));
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  function handleChange(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelected(selectVal);
    history.push(`${url}/${selectVal.value}`);
    console.log(`Option selected:`, selectVal);
  };

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <div>
            <h2>Manage Content</h2>
            <hr/>
            <h5>
              You can use this page to edit information and pricing associated with your content.
            </h5>
            {!selected &&
            <p>Please select a book to manage.</p>
            }
            {selectOptions &&
            <div>
              <h5><b>Book</b>:</h5>
              <Select
                value={selected}
                onChange={handleChange}
                options={selectOptions}
              />
            </div>}
          </div>

          {bookData && genreData && authorId &&
          <Route path={`${url}/:bookId`}>
            <Book
              books={bookData}
              genreData={genreData}
              authorId={authorId}
              fetchAuthor={fetchAuthor}
            />
          </Route>}
        </div>
      </div>
    </div>
  );
}

const Book = ({
  books,
  genreData,
  authorId,
  fetchAuthor,
}: {
  books: Map<string, BookData>,
  genreData: FetchGenreData,
  authorId: number,
  fetchAuthor: (id: any) => void,
}) => {
  const history = useHistory();
  const { bookId } = useParams<{bookId: string}>();
  const [book, setBook] = useState<BookData | undefined>(books.get(bookId));
  const [chapterSelected, setChapterSelected] = useState<SelectOption | null>(null);
  const [freeChaptersSelected, setFreeChaptersSelected] = useState<SelectOption | null>(null);
  const [chapterData, setChapterData] = useState<ChapterData | null>(null);
  const [chapterPricing, setChapterPricing] = useState<string>("0");
  const [chapterName, setChapterName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [shortDescription, setShortDescription] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [mtChecked, setMtChecked] = useState(false);
  const [seasonPassEnabled, setSeasonPassEnabled] = useState(true);
  const [unlockableByReading, setuUnlockableByReading] = useState(false);
  const [images, setImages] = useState<Array<ImageType>>([]);
  const [updateBookGraphql] = useMutation<
    {updateBook: BookData},
    {data: BookUpdateInput}
    >(UPDATE_BOOK);
  const [updateChapterGraphql] = useMutation<
    {updateChapter: ChapterData},
    {data: ChapterUpdateInput}
    >(UPDATE_CHAPTER);
  const [updateSeasonGraphql] = useMutation<
    {updateSeason: SeasonData},
    {data: SeasonUpdateInput}
    >(UPDATE_SEASON);
  const [fetchSeasons, {data: allSeasonsData, error: allSeasonsError}] = useLazyQuery<FetchSeasonData>(FETCH_SEASONS);
  const [seasonFreeChaptersOptions, setSeasonFreeChaptersOptions] = useState<SelectOption[] | null>(null);
  const [seasonOptions, setSeasonOptions] = useState<SelectOption[] | null>(null);
  const [seasonSelected, setSeasonSelected] = useState<SelectOption | null>(null);
  const [seasonData, setSeasonData] = useState<SeasonData | null>(null);
  const [seasonPricing, setSeasonPricing] = useState<string>("0");
  const [constantChapterPricing, setConstantChapterPricing] = useState<string>("");
  const [seasonName, setSeasonName] = useState<string>("");
  const [seasonDescription, setSeasonDescription] = useState<string>("");

  React.useEffect(() => {
    if (fetchSeasons) {
      fetchSeasons({
        variables: {bookId: Number(bookId)},
      });
    }
  }, [bookId, fetchSeasons]);

  React.useEffect(() => {
    if (books) {
      setBook(books.get(bookId));
    }
  }, [books, bookId]);

  React.useEffect(() => {
    if (allSeasonsData) {
      console.log('------------received season data');
      console.log(allSeasonsData);
      const seasonOptions: SelectOption[] = [];
      for (let season of allSeasonsData.allSeasons) {
        console.log(season);
        seasonOptions.push({
          value: season.id.toString(),
          label: season.name,
        });
      }
      setSeasonOptions(seasonOptions);
    }
    if (allSeasonsError) {
      console.log(allSeasonsError);
    }
  }, [allSeasonsData, allSeasonsError]);

  const genreOptions: SelectOption[] = useMemo(() =>
    genreData.genres.map(genre => ({
      value: genre.id.toString(),
      label: genre.displayName,
    }))
    , [genreData]
  );
  const idToGenreOption: Map<number, SelectOption> = useMemo(() =>
    new Map(genreData.genres.map((genre, i) => [genre.id, genreOptions[i]]))
    , [genreOptions, genreData]
  );
  const [genres, setGenres] = useState<SelectOption[]>([]);

  React.useEffect(() => {
    if (book) {
      console.log("checked " + book.matureThemes);
      setMtChecked(book.matureThemes);
      setuUnlockableByReading(book.unlockableByReading);
      setTitle(book.name);
      setShortDescription(book.shortDescription);
      setDescription(book.description);
      const bookThemes: SelectOption[] = [];
      for (let genre of book!.genres) {
        bookThemes.push(idToGenreOption.get(genre.id)!);
      }
      setGenres(bookThemes);
      // reset chapter/season if book changed
      if (chapterSelected) {
        const chapterId = chapterSelected.value;
        const chapter = book!.chapters.find((c: ChapterData) => c.id === Number(chapterId)) ?? null;
        if (chapter === null) {
          setChapterSelected(null);
        }
      }
      if (seasonSelected && allSeasonsData) {
        const seasonId = seasonSelected.value;
        const season = allSeasonsData!.allSeasons.find((s: SeasonData) => s.id === Number(seasonId)) ?? null;
        if (season === null) {
          setSeasonSelected(null);
        }
      }
    }
  }, [idToGenreOption, book, chapterSelected, seasonSelected, allSeasonsData]);

  if (!books || !book) {
    history.replace('/content');
    return (<div></div>);
  }

  function handleChangeChapter(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setChapterSelected(selectVal);
    const chapterId = selectVal.value;
    const chapter = book!.chapters.find((c: ChapterData) => c.id === Number(chapterId)) ?? null;
    if (chapter === null) {
      return;
    }
    setChapterData(chapter);
    setChapterPricing(chapter.beanPrice.toString());
    setChapterName(chapter.name);
    console.log(`Option selected:`, selectVal);
  };

  const chapterOptions: SelectOption[] = [];
  for (let chapter of book.chapters) {
    chapterOptions.push({
      value: chapter.id.toString(),
      label: chapter.name,
    });
  }

  function handleChangeSeason(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSeasonSelected(selectVal);
    const seasonId = selectVal.value;
    const season = allSeasonsData!.allSeasons.find((s: SeasonData) => s.id === Number(seasonId)) ?? null;
    if (season === null) {
      return;
    }
    setSeasonData(season);
    setSeasonPricing(season.seasonPassBeanPrice.toString());
    setSeasonPassEnabled(!season.disableSeasonPass);
    setSeasonName(season.name);
    setSeasonDescription(season.description);
    setFreeChaptersSelected(null);
    const seasonFreeChaptersOpts: SelectOption[] = [];
    for (let chapter of book!.chapters) {
      console.log(season);
      if (chapter.chapterIndex < 3) {
        continue
      }
      seasonFreeChaptersOpts.push({
        value: chapter.chapterIndex.toString(),
        label: chapter.chapterIndex.toString(),
      });
    }
    setSeasonFreeChaptersOptions(seasonFreeChaptersOpts);
    console.log(`Option selected:`, selectVal);
  };

  async function handleBookUpdate() {
    if (book === null) {
      return;
    }
    console.log("handle book update");
    const genreList = genres.map(g => Number(g.value));
    const responseData = await updateBookGraphql({
      variables: {
        data: {
          id: book!.id,
          name: title,
          shortDescription: shortDescription,
          description: description,
          genres: genreList,
          matureThemes: mtChecked,
          unlockableByReading: unlockableByReading,
          file: images.length === 1 ? images[0].file : null
        },
      },
    }).then(() => createToast("Book information updated."))
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function handleChapterUpdate() {
    if (chapterData === null) {
      return;
    }
    console.log("handle chapter update");
    const responseData = await updateChapterGraphql({
      variables: {
        data: {
          id: chapterData!.id,
          name: chapterName,
          baseBeanPrice: Number(chapterPricing)
        },
      },
    }).then(() => createToast("Chapter information updated."))
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function handleSeasonUpdate() {
    if (seasonData === null) {
      return;
    }
    console.log("handle season update");
    const chapterPricing = constantChapterPricing.length === 0 ? null : Number(constantChapterPricing);
    const responseData = await updateSeasonGraphql({
      variables: {
        data: {
          id: seasonData!.id,
          name: seasonName,
          description: seasonDescription,
          seasonPassBeanPrice: Number(seasonPricing),
          disableSeasonPass: !seasonPassEnabled,
          freeChapters: freeChaptersSelected === null ? null : Number(freeChaptersSelected.value),
          constantChapterPricing: chapterPricing,
        },
      },
    }).then(() => {
      createToast("Season information updated.");
      if (chapterPricing !== null) {
        fetchAuthor({
          variables: {id: authorId!.toString()},
        });
      }
    }).catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  function validateUpdateBook() {
    return title.length > 0;
  }

  function validateUpdateChapter() {
    console.log(parseInt(chapterPricing));
    return !Number.isNaN(parseInt(chapterPricing)) && Number(chapterPricing) >= 0;
  }

  function validPrice(num: string) {
    return !Number.isNaN(parseInt(num)) && Number(num) >= 0;
  }

  function validateUpdateSeason() {
    return validPrice(seasonPricing)
      && (constantChapterPricing.length === 0 || validPrice(constantChapterPricing));
  }

  function handleCheck(check: boolean) {
    console.log(check);
    setMtChecked(check);
  }

  function handleCheckUnlockableByReading(check: boolean) {
    console.log(check);
    setuUnlockableByReading(check);
  }

  function handleGenreSelect(selectVals: SelectOption[]) {
    console.log(`Option selected:`, selectVals);
    const updatedGenres: SelectOption[] = [];
    for (let select of selectVals) {
      updatedGenres.push(idToGenreOption.get(Number(select.value))!);
    }
    setGenres(updatedGenres);
  };

  const onUploadCover = (imageList: ImageType[], addUpdateIndex: number[] | undefined) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  function handleCheckSeasonPass(e: any) {
    setSeasonPassEnabled(e.target.checked);
  }

  function handleFreeChaptersSelect(selectVal: SelectOption | null) {
    setFreeChaptersSelected(selectVal);
  };

  return (
    <div>
      <div>
        <br></br>
        <img
          src={(images.length === 1) ?
            images[0].data_url :
            (book.coverImageUrl ? book.coverImageUrl : "https://res.cloudinary.com/stori/image/upload/v1643098767/stori_images/default_cover_tkof4k.png")}
          alt={"No cover available"}
          height={"30%"}
          width={"30%"}
          className="center"
        />
        <br></br>
        <Card className="EditBook">
          <Card.Header>Edit Book</Card.Header>
          <Card.Body>
            <Form onSubmit={(e) => {
              e.preventDefault();
            }}>
              <FormInput
                fieldName="Title"
                value={title}
                type="text"
                onChangeText={(e) => setTitle(e.target.value)}
              />
              <FormInput
                fieldName="Short Description"
                value={shortDescription}
                type="text"
                rows={2}
                onChangeText={(e) => setShortDescription(e.target.value)}
              />
              <FormInput
                fieldName="Description"
                value={description}
                type="text"
                rows={5}
                onChangeText={(e) => setDescription(e.target.value)}
              />
              <FormUploadImage
                fieldName="Cover"
                images={images}
                onChange={onUploadCover}
                maxNumber={1}
                // TODO support slack
                // resolutionType={"ratio"}
                // resolutionWidth={2}
                // resolutionHeight={3}
              />
              <p>* Cover will be resized to 800x1200.</p>
              <FormSelect
                fieldName={"Genres"}
                options={genreOptions}
                onChange={handleGenreSelect}
                value={genres}
                multiSelect={true}
              />
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Mature Themes"
                  checked={mtChecked}
                  onChange={(e) => {
                      handleCheck(e.target.checked);
                    }
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Unlockable By Reading"
                  checked={unlockableByReading}
                  onChange={(e) => {
                      handleCheckUnlockableByReading(e.target.checked);
                    }
                  }
                />
              </Form.Group>
              <LoginButton
                text="Update"
                type="submit"
                disabled={!validateUpdateBook()}
                onClick={handleBookUpdate}
              />
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div>
        <div className="Splitscreen">
          <div className="left">
            <h5><b>Chapters</b>:</h5>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Season</th>
                <th>Bean Price</th>
              </tr>
              </thead>
              <tbody>
              {book && book.chapters.map((chapter, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{chapter.name}</td>
                  <td>{chapter.seasonIndex}</td>
                  <td>{(chapter.beanPrice === 0) ? "Free" : chapter.beanPrice}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
          <div className="right">
            <br/>
            {!chapterSelected &&
            <p>Please select a chapter to manage chapter-specific pricing.</p>
            }
            <Select
              value={chapterSelected}
              onChange={handleChangeChapter}
              options={chapterOptions}
            />
            {chapterSelected && chapterData &&
            <div>
              <br></br>
                <div>
                  <Card className="EditChapter">
                    <Card.Header>Edit Chapter</Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleChapterUpdate();
                      }}>
                        <FormInput
                          fieldName="Name"
                          value={chapterName}
                          type="text"
                          onChangeText={(e) => setChapterName(e.target.value)}
                        />
                        <FormInput
                          fieldName="Bean Price"
                          value={chapterPricing}
                          type="text"
                          onChangeText={(e) => setChapterPricing(e.target.value)}
                          disabled={chapterData.chapterIndex <= 3}
                        />
                        <Form.Label>
                          {"Status"}
                        </Form.Label>
                        {Number(chapterPricing) === 0 ?
                          (<p><b>Free</b></p>)
                          :(<p><b>Locked</b></p>)
                        }
                        <LoginButton
                          text="Update"
                          type="submit"
                          disabled={!validateUpdateChapter()}
                        />
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
            </div>
            }
          </div>
        </div>
      </div>
      {/*season */}
      <div>
        <div className="Splitscreen">
          <div className="left">
            <h5><b>Seasons</b>:</h5>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th>Bean Price</th>
              </tr>
              </thead>
              <tbody>
              {allSeasonsData && allSeasonsData.allSeasons.map((season, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{season.name}</td>
                  <td>{season.description}</td>
                  <td>{season.disableSeasonPass ? "N/A":
                    (season.seasonPassBeanPrice === 0) ? "Free" : season.seasonPassBeanPrice}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
          <div className="right">
            <br/>
            {!chapterSelected &&
              <p>Please select a season to manage season-specific pricing.</p>
            }
            <Select
              value={seasonSelected}
              onChange={handleChangeSeason}
              options={seasonOptions}
            />
            {seasonSelected && seasonData &&
              <div>
                <br></br>
                <div>
                  <Card className="EditChapter">
                    <Card.Header>Edit Season</Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleSeasonUpdate();
                      }}>
                        <FormInput
                          fieldName="Name"
                          value={seasonName}
                          type="text"
                          onChangeText={(e) => setSeasonName(e.target.value)}
                        />
                        <FormInput
                          fieldName="Description"
                          value={seasonDescription}
                          type="text"
                          onChangeText={(e) => setSeasonDescription(e.target.value)}
                          rows={3}
                        />
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            type="checkbox"
                            label="Season Pass Available"
                            checked={seasonPassEnabled}
                            onChange={handleCheckSeasonPass}
                          />
                        </Form.Group>
                        <FormInput
                          fieldName="Season Pass Bean Price"
                          value={seasonPricing}
                          type="text"
                          onChangeText={(e) => setSeasonPricing(e.target.value)}
                        />
                        {seasonFreeChaptersOptions &&
                          <FormSelect
                            fieldName={"Free Chapters"}
                            options={seasonFreeChaptersOptions}
                            onChange={handleFreeChaptersSelect}
                            value={freeChaptersSelected}
                          />
                        }
                        <FormInput
                          fieldName="Per Chapter Bean Price (Optional)"
                          value={constantChapterPricing}
                          type="text"
                          onChangeText={(e) => setConstantChapterPricing(e.target.value)}
                        />
                        <LoginButton
                          text="Update"
                          type="submit"
                          disabled={!validateUpdateSeason()}
                        />
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
