import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormInput from "../components/FormInput";
import {useAuthContext} from "../context/AuthContext";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";

export const FETCH_USER = gql`
  query FetchUser {
    viewer {
      id
      isAuthor
    }
  }
`;

// TODO: probably want separate css/components for button from login
export function RegisterAuthorPage () {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [registered, setRegistered] = React.useState<boolean>(false);
  const {user, authorRegistration, registerAuthor, logout} = useAuthContext();
  const { data: fetchViewerData, error: fetchViewerError } = useQuery(FETCH_USER);

  async function handleRegister() {
    console.log("handle register");
    if (!email.includes("@")) {
      createToast("Please enter a valid email with '@'.");
      return;
    }
    console.log(name);
    console.log(email);
    console.log(additionalInfo);
    await registerAuthor(name, email, additionalInfo);
    createToast("Author registration submitted.");
  }

  function validateForm() {
    return email.length > 0 && name.length > 0;
  }

  React.useEffect(() => {
    if (fetchViewerData && history) {
      console.log("fetch viewer");
      console.log(fetchViewerData);
      if (user && fetchViewerData.viewer.isAuthor) {
        // force user to re login to update user in authcontext cache
        logout();
        history.push('/login');
      }
    }
    if (fetchViewerError) {
      console.log("fetch viewer error");
      console.log(fetchViewerError);
    }
  }, [user, fetchViewerData, fetchViewerError, history, logout]);

  console.log("auth context");
  console.log(user);
  console.log(authorRegistration);
  // initialize form with either a existing application or user signup data
  React.useEffect(() => {
    if (authorRegistration) {
      setName(authorRegistration.name);
      setEmail(authorRegistration.email);
      setAdditionalInfo(authorRegistration.additionalInfo);
      setRegistered(true);
    } else if (user) {
      if (user.name) {
        setName(user.name);
      }
      setEmail(user.email);
    }
  }, [user, authorRegistration, registered]);

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <h2>Register Author</h2>
          <hr/>
          <h4>Your account is currently not registered as an author.
            If you are an author please fill the form below to register as an author and we will process your registration shortly.
          </h4>
          <hr/>
          <h5>Please register with the name and email you used to sign the author contract so we can verify your identity.</h5>
          <Card className="RegisterAuthor">
            <Card.Header>Author Registration</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleRegister();
              }}>
                <FormInput
                  fieldName="Legal Name"
                  value={name}
                  type="text"
                  onChangeText={(e) => setName(e.target.value)}
                />
                <FormInput
                  fieldName="Email"
                  value={email}
                  type="email"
                  onChangeText={(e) => setEmail(e.target.value)}
                />
                <FormInput
                  fieldName="Additional Information"
                  value={additionalInfo}
                  type="text"
                  rows={3}
                  onChangeText={(e) => setAdditionalInfo(e.target.value)}
                />
                <LoginButton
                  text="Register"
                  type="submit"
                  disabled={!validateForm()}
                />
                {registered &&
                <Form.Label>
                  * Your application has been submitted and is under review.
                </Form.Label>
                }
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
