import React from 'react';
import {Helmet} from "react-helmet";

// TODO: use a randomly generated state and use to verify successful authorizations
const AppleLoginButton = () => {
  return (
    <div style={{
      width: '100%',
      height: '40px',
      fontWeight: 'bold',
      marginTop: '15px',
      marginBottom: '15px',
    }}
         id="appleid-signin"
         data-color="black"
         data-border="true"
         data-type="sign in">
      <Helmet>
        <meta name="appleid-signin-client-id" content="com.stori.authorportal"/>
        <meta name="appleid-signin-scope" content="name email"/>
        <meta name="appleid-signin-redirect-uri" content="https://naughty-northcutt-d679f8.netlify.app/home"/>
        <meta name="appleid-signin-state" content="sdfsdfsdf"/>
        <meta name="appleid-signin-use-popup" content="true"/>
        <script type="text/javascript" src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"></script>
      </Helmet>
    </div>
  );
};

export default AppleLoginButton;
