import React, {useState} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import WebToastContainer from "../components/WebToastContainer";
import {useHistory} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
// @ts-ignore
import AdminHeader from "../components/AdminHeader";
import Table from "react-bootstrap/Table";

interface UserData {
  id: number;
  email: string;
}

interface FetchUserData {
  allUsers: Array<UserData>;
}

export const ALL_USERS_FRAGMENT = `
allUsers {
  id
  email
}
`;

export const ALL_USERS = gql`
  query FetchUsers {
    ${ALL_USERS_FRAGMENT}
  }
`;

export function AdminUsersPage () {
  const history = useHistory();
  const [loggedIn] = useState(false);
  const [users, setUsers] = useState<UserData[]>([]);
  const [userStr, setUserStr] = useState<string>("");
  const {data: userData} = useQuery<FetchUserData>(ALL_USERS);

  React.useEffect(() => {
    if (loggedIn) {
      history.push('/home');
    }
  }, [loggedIn, history]);

  React.useEffect(() => {
    if (userData) {
      console.log("user data");
      console.log(userData);
      let userListStr: string = "";
      for (let user of userData.allUsers) {
        userListStr += user.email
        userListStr += ", "
      }
      setUserStr(userListStr)
      setUsers(userData.allUsers)
    }
  }, [userData]);

  function renderRows() {
    return users.map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.email}
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Users</h2>
          <hr/>
          <h5>
            User Emails:
          </h5>
          <br></br>
          <Table striped bordered hover size="sm">
            <thead>
            <tr>
              <th>Email</th>
            </tr>
            </thead>
            <tbody>
            {userData && renderRows()}
            </tbody>
          </Table>
          {userStr}
        </div>
      </div>
    </div>
  );
};
