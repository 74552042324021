import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
// @ts-ignore
import Select from 'react-select';
import {SelectOption, BookCouponData, BookCoupon} from "../Types";
import AdminHeader from "../components/AdminHeader";
import Table from "react-bootstrap/Table";
import FormInput from "../components/FormInput";
import {FormDate} from "../components/FormDate";

interface BookData {
  id: string;
  name: string;
}

interface FetchBookData {
  allBooks: Array<BookData>;
}

export const FETCH_BOOKS = gql`
  query allBooks {
    allBooks {
      id
      name
    }
  }
`;

const COUPON_QUERY = `
id
expiresAt
chapterUnlockCount
remainingCoupons
couponsRedeemed
universal
disabled
author_name
book_name
`;

interface FetchUniversalCouponData {
  universalCoupons: Array<BookCouponData>;
}

export const FETCH_UNIVERSAL_COUPONS = gql`
  query FetchUniversalCoupons {
    universalCoupons {
      ${COUPON_QUERY}
    }
  }
`;

interface CreateCouponInput {
  bookId: number;
  numberOfCoupons: number;
  expiresAt: Date;
  chapterUnlockCount: number;
  universal: boolean;
}

const CREATE_COUPON = gql`
  mutation CreateCoupon($data: CreateCouponInput!) {
    createCoupon(data: $data) {
      ${COUPON_QUERY}
    }
  }
`;

const ENABLE_DISABLE_COUPON = gql`
  mutation EnableDisableCoupon($id: String!, $disabled: Boolean!) {
    enableDisableCoupon(id: $id, disabled: $disabled) {
      ${COUPON_QUERY}
    }
  }
`;

const APPLY_COUPON = gql`
  mutation ApplyUniversalCoupon($couponId: String!) {
    applyUniversalCoupon(couponId: $couponId)
  }
`;

export function AdminCouponPage () {
  const [selectOptions, setSelectOptions] = useState<SelectOption[] | null>(null);
  const [selected, setSelected] = useState<SelectOption | null>(null);
  const [couponInfo, setCouponInfo] = useState<BookCouponData[]>([]);
  const [expiry, setExpiry] = useState<Date | null>(new Date());
  const [chaptersUnlocked, setChaptersUnlocked] = useState<string>("");
  const [showExpired, setShowExpired] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [fetchCoupons, {data: universalCouponData, error: universalCouponError}] = useLazyQuery<FetchUniversalCouponData>(FETCH_UNIVERSAL_COUPONS, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const [fetchBooks, {data: bookData, error: bookError}] = useLazyQuery<FetchBookData>(FETCH_BOOKS);
  const [createCouponGraphql] = useMutation<
    {createCoupon: BookCoupon},
    {data: CreateCouponInput}
    >(CREATE_COUPON);
  const [enableDisableCouponGraphql] = useMutation<
    {enableDisableCoupon: BookCoupon},
    {id: String, disabled: Boolean}
    >(ENABLE_DISABLE_COUPON);
  const [applyCouponGraphql] = useMutation<
    {applyCoupon: boolean},
    {couponId: String}
    >(APPLY_COUPON);

  React.useEffect(() => {
    console.log("fetching coupons");
    if (fetchCoupons) {
      fetchCoupons();
    }
  }, [fetchCoupons]);

  React.useEffect(() => {
    console.log("fetching books");
    if (fetchBooks) {
      fetchBooks();
    }
  }, [fetchBooks]);

  React.useEffect(() => {
    if (bookData) {
      console.log('------------received book data');
      console.log(bookData);
      const bookOptions: SelectOption[] = [];
      for (let book of bookData.allBooks) {
        console.log(book);
        bookOptions.push({
          value: book.id.toString(),
          label: book.name,
        });
      }
      setSelectOptions(bookOptions);
    }
    if (bookError) {
      console.log(bookError);
    }
  }, [bookData, bookError]);

  React.useEffect(() => {
    if (universalCouponData) {
      console.log('------------received universal coupon data');
      console.log(universalCouponData);
      const currentTime: Date = new Date();
      setCouponInfo(universalCouponData.universalCoupons.filter((o) => {
        return (showExpired || new Date(o.expiresAt) > currentTime) && (showDisabled || !o.disabled);
      }));
    }
    if (universalCouponError) {
      console.log(universalCouponError);
    }
  }, [universalCouponData, universalCouponError, showExpired, showDisabled]);

  async function applyCoupon(i: number) {
    const responseData = await applyCouponGraphql({
      variables: {
        couponId: couponInfo[i].id,
      },
    }).then(() => {
      createToast("Coupon applied.");
      fetchCoupons();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function couponDelete(i: number) {
    const action = couponInfo[i].disabled ? "enabled" : "disabled";
    const responseData = await enableDisableCouponGraphql({
      variables: {
        id: couponInfo[i].id,
        disabled: !couponInfo[i].disabled,
      },
    }).then(() => {
      createToast(`Coupon ${action}.`);
      fetchCoupons();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  function isNumber(value: string): boolean {
    return !Number.isNaN(parseInt(value)) && Number(value) >= 0;
  }

  function validateCreateCoupon() {
    return isNumber(chaptersUnlocked) && selected != null;
  }

  function renderRows() {
    const currentTime: Date = new Date();
    return couponInfo.filter((o) => {
      return (showExpired || new Date(o.expiresAt) > currentTime) && (showDisabled || !o.disabled);
    }).map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.author_name}
          </td>
          <td>
            {o.book_name}
          </td>
          <td>
            {o.id}
          </td>
          <td>
            {o.chapterUnlockCount}
          </td>
          <td>
            {o.couponsRedeemed}
          </td>
          <td>
            {o.expiresAt.toString().substring(0, 10)}
          </td>
          <td>
            <LoginButton
              text="Apply"
              type="button"
              disabled={o.disabled}
              onClick={() => applyCoupon(i)}
            />
            <LoginButton
              text={o.disabled ? "Enable" : "Disable"}
              type="button"
              disabled={false}
              onClick={() => couponDelete(i)}
            />
          </td>
        </tr>
      );
    });
  }

  async function handleCreateCoupon() {
    const responseData = await createCouponGraphql({
      variables: {
        data: {
          bookId: Number(selected!.value),
          numberOfCoupons: 0,
          expiresAt: expiry!,
          chapterUnlockCount: Number(chaptersUnlocked),
          universal: true,
        },
      },
    }).then(() => {
      createToast("Coupon created.");
      fetchCoupons();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  function selectBook(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelected(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  function handleCheckExpired(e: any) {
    setShowExpired(e.target.checked);
  }

  function handleCheckDisabled(e: any) {
    setShowDisabled(e.target.checked);
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Coupon</h2>
          <hr/>
          <h5>
            Manage universal coupons as an admin.
          </h5>
          <hr/>
          <div>
            <br></br>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Author</th>
                <th>Book</th>
                <th>Coupon Code</th>
                <th>Chapter Unlocks</th>
                <th># Redeemed</th>
                <th>Expires</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {couponInfo && renderRows()}
              </tbody>
            </Table>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Show expired coupons"
                onChange={handleCheckExpired}
              />
              <Form.Check
                type="checkbox"
                label="Show disabled coupons"
                onChange={handleCheckDisabled}
              />
            </Form.Group>
            <hr/>
            <Card className="CreateCoupon">
              <Card.Header>Create New Universal Coupon</Card.Header>
              <Card.Body>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  // handleUpdate();
                }}>
                  {selectOptions &&
                    <div>
                      <Form.Label>
                        Book
                      </Form.Label>
                      <Select
                        value={selected}
                        onChange={selectBook}
                        options={selectOptions}
                      />
                    </div>}
                  <FormInput
                    fieldName="Chapters Unlocked Per Coupon"
                    value={chaptersUnlocked}
                    type="text"
                    onChangeText={(e) => setChaptersUnlocked(e.target.value)}
                  />
                  <FormDate
                    label={"Expiry Date"}
                    value={expiry}
                    minDate={new Date()}
                    onChange={(date: Date | null) => {
                      if (date === null || date instanceof Date) {
                        setExpiry(date);
                      }
                    }}
                  />
                  <LoginButton
                    text="Create Coupon"
                    type="button"
                    disabled={!validateCreateCoupon()}
                    onClick={handleCreateCoupon}
                  />
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
