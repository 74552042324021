import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import '../App.css';
import LoginInput from "../components/LoginInput";
import LoginButton from "../components/LoginButton";
import FormTextButton from "../components/FormTextButton";
import {useHistory} from "react-router-dom";
import {CreateUserResult, useAuthContext} from "../context/AuthContext";
import WebToastContainer from "../components/WebToastContainer";
import {createToast} from "../components/WebToast";

export function CreateAccountPage() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const {createUser} = useAuthContext();

  function handleCreateAccount(email: string, username: string, password: string) {
    createUser(email, username, password).then(result => {
      if (result === CreateUserResult.SUCCESS) {
        history.push('/home');
      } else {
        let msg;
        if (result === CreateUserResult.EMAIL_IN_USE) {
          msg = `Email ${email} already taken. Please choose another one.`;
        } else if (result === CreateUserResult.USERNAME_IN_USE) {
          msg = `Username ${username} already taken. Please choose another one.`;
        } else {
          msg = 'Error signing up. Please try again.';
        }
        createToast(msg);
      }
    })
  }

  function validateForm() {
    return email.length > 0;
  }

  function toLogin() {
    console.log("Login");
    history.push("/login");
  }

  return (
    <div>
      <WebToastContainer/>
      <Card className="Login">
        <Card.Header>Create Account</Card.Header>
        <Card.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleCreateAccount(email, username, password);
          }}>
            <LoginInput
              placeholder="Email"
              type="email"
              onChangeText={(e) => setEmail(e.target.value)}
            />
            <LoginInput
              placeholder="Username"
              type="text"
              onChangeText={(e) => setUsername(e.target.value)}
            />
            <LoginInput
              placeholder="Password"
              type="password"
              onChangeText={(e) => setPassword(e.target.value)}
            />
            <LoginButton
              text="Create Account"
              type="submit"
              disabled={!validateForm()}
            />
            <FormTextButton onClick={toLogin} text={"Login"}/>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
