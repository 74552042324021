import React from 'react';
import {useAuthContext} from "../context/AuthContext";
import FormTextButton from "./FormTextButton";
import {useHistory} from "react-router-dom";

const AdminHeader = () => {
  const history = useHistory();
  const {adminLoginAsAuthor, author, returnToAdmin} = useAuthContext();

  async function handleReturn() {
    console.log("return to admin");
    await returnToAdmin();
    history.push('/admin-login');
  }

  return (
    <div>
      {author && adminLoginAsAuthor &&
        <div>
          <h5>You are logged into author {author.alias}'s account as an admin.</h5>
          <FormTextButton onClick={handleReturn} text={"Return to Admin Account"}/>
          <hr/>
        </div>
      }
    </div>
  );
};


export default AdminHeader;
