import React, {useState} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import {gql, useLazyQuery} from '@apollo/client';
// @ts-ignore
import Select from 'react-select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {useAuthContext} from "../context/AuthContext";
import {Author} from "../Types";
import AdminHeader from "../components/AdminHeader";
import Table from "react-bootstrap/Table";

const BOOK_QUERY = `
id
name
author {
  name
}
chapters {
  id
  chapterIndex
  name
  beanPrice
  likeCount
}
shortDescription
coverImageUrl
viewCount
followerCount
readerCount
`;

interface BookAuthor {
  id: number;
  name: string;
}

interface ChapterData {
  id: number;
  chapterIndex: number;
  name: string;
  beanPrice: number;
  likeCount: number;
}

interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  shortDescription: string;
  author: BookAuthor;
  chapters: Array<ChapterData>;
  viewCount: number;
  followerCount: number;
  readerCount: number;
}

interface AuthorData {
  id: string;
  name: string;
  description: string;
  twitterHandle?: string;
  isFollowing: boolean;
  books: Array<BookData>;
  profilePicUrl: string;
}

interface AuthorPaymentData {
  id: number;
  paidAt: Date;
  authorId: number;
  grossRevenueMicros: number;
  royaltiesMicros: number;
  paypalFeesMicros: number;
  paymentMicros: number;
}

interface FetchAuthorData {
  authorById: AuthorData;
}

interface FetchAuthorPaymentsData {
  authorPayments: Array<AuthorPaymentData>;
}

interface MetricAggregation {
  sum: number;
  doy?: number;
  week?: number;
  month?: number;
  year?: number;
}

export interface RevenueData {
  revenue: Array<MetricAggregation>;
}

export interface BookViewData {
  bookViews: Array<MetricAggregation>;
}

export interface ChapterReadData {
  chapterReads: Array<MetricAggregation>;
}

interface SelectOption {
  value: string;
  label: string;
}

// TODO: maybe we should use a sql query to fetch all the book/chapter information for better efficiency
export const FETCH_AUTHOR = gql`
  query FetchAuthorById($id: String!) {
    authorById(id: $id) {
      id
      name
      description
      twitterHandle
      isFollowing
      books {
          ${BOOK_QUERY}
      }
      profilePicUrl
    }
  }
`;

export const FETCH_AUTHOR_PAYMENTS = gql`
  query FetchAuthorPayments($authorId: Int!) {
    authorPayments(authorId: $authorId) {
      id
      paidAt
      authorId
      grossRevenueMicros
      royaltiesMicros
      paypalFeesMicros
      paymentMicros
    }
  }
`;

export const FETCH_REVENUE = gql`
  query FetchRevenue($id: Int, $bookId: Int, $chapterId: Int, $dateFrom: DateTime, $dateTo: DateTime, $groupBy: String, $filterUnpaid: Boolean) {
    revenue(id: $id, bookId: $bookId, chapterId: $chapterId, dateFrom: $dateFrom, dateTo: $dateTo, groupBy: $groupBy, filterUnpaid: $filterUnpaid) {
      sum
      doy
      week
      month
      year
    }
  }
`;

export const FETCH_BOOK_VIEWS = gql`
  query FetchBookViews($id: Int, $bookId: Int, $dateFrom: DateTime, $dateTo: DateTime, $groupBy: String!) {
    bookViews(id: $id, bookId: $bookId, dateFrom: $dateFrom, dateTo: $dateTo, groupBy: $groupBy) {
      sum
      doy
      week
      month
      year
    }
  }
`;

export const FETCH_CHAPTER_READS = gql`
  query FetchChapterReads($id: Int, $bookId: Int, $chapterId: Int, $dateFrom: DateTime, $dateTo: DateTime, $groupBy: String!) {
    chapterReads(id: $id, bookId: $bookId, chapterId: $chapterId, dateFrom: $dateFrom, dateTo: $dateTo, groupBy: $groupBy) {
      sum
      doy
      week
      month
      year
    }
  }
`;

const ALL = "All";
const ALL_OPTION = {
  value: ALL,
  label: ALL,
}
// Doy stands for day of year
const PERIOD_OPTIONS = [
  {
    value: "Doy",
    label: "Last Day",
  },
  {
    value: "Week",
    label: "Last 7 Days",
  },
  {
    value: "Month",
    label: "Last 30 Days",
  },
  {
    value: "AllTime",
    label: "All Time",
  }
]
export default PERIOD_OPTIONS;
const GRAPH_PERIODS = 7;

// TODO: handle seasons
export function AnalyticsPage() {
  const [bookData, setBookData] = useState<BookData[] | null>(null);
  const [authorPayments, setAuthorPayments] = useState<AuthorPaymentData[] | null>(null);
  const [revenue, setRevenue] = useState<number | null>(null);
  const [royalties, setRoyalties] = useState<number | null>(null);

  // Select Options
  const [allBookOptions, setAllBookOptions] = useState<SelectOption[]>([]);
  const [bookOptions, setBookOptions] = useState<SelectOption[]>([ALL_OPTION]);
  const [allChapterOptions, setAllChapterOptions] = useState<{ [key:string]:SelectOption[] }>({});
  const [chapterOptions, setChapterOptions] = useState<SelectOption[]>([ALL_OPTION]);
  const [bookSelect, setBookSelect] = useState<SelectOption>(ALL_OPTION);
  const [chapterSelect, setChapterSelect] = useState<SelectOption>(ALL_OPTION);
  const [periodSelect, setPeriodSelect] = useState<SelectOption>({
    value: "AllTime",
    label: "All Time",
  });

  const {author} = useAuthContext();
  const [fetchAuthor, {data, error}] = useLazyQuery<FetchAuthorData>(FETCH_AUTHOR);
  const [fetchAuthorPayments, {data:authorPaymentData, error:authorPaymentError}] = useLazyQuery<FetchAuthorPaymentsData>(FETCH_AUTHOR_PAYMENTS);
  // TODO: Might want to cache the revenue queries at the expense of providing stale data
  const [fetchRevenue, {data:revenueData, error:revenueError}] = useLazyQuery<RevenueData>(FETCH_REVENUE, {fetchPolicy: 'network-only'});
  const [fetchRevChartData, {data:revChartData, error:revChartError}] = useLazyQuery<RevenueData>(FETCH_REVENUE);
  const [fetchBVChartData, {data:bvChartData, error:bvChartError}] = useLazyQuery<BookViewData>(FETCH_BOOK_VIEWS);
  const [fetchCRChartData, {data:crChartData, error:crChartError}] = useLazyQuery<ChapterReadData>(FETCH_CHAPTER_READS);
  const [revenueHistory, setRevenueHistory] = useState<Array<MetricAggregation> | null>(null);
  const [bookViewHistory, setBookViewHistory] = useState<Array<MetricAggregation> | null>(null);
  const [chapterReadHistory, setChapterReadHistory] = useState<Array<MetricAggregation> | null>(null);

  React.useEffect(() => {
    if (author) {
      fetchAuthor({
        variables: {id: author.id.toString()},
      });
      fetchAuthorPayments({
        variables: {authorId: author.id},
      });
      fetchRevenue({
        variables: {id: author.id, filterUnpaid: true},
      });
    }
  }, [fetchAuthor, author, fetchAuthorPayments, fetchRevenue]);

  React.useEffect(() => {
    if (data) {
      console.log('analytic data');
      console.log(data);
      const bookOptionsVar: SelectOption[] = [];
      const allChapterOptionsVar : { [key:string]:SelectOption[] } = {};
      for (let book of data.authorById.books) {
        console.log(book);
        bookOptionsVar.push({
          value: book.id.toString(),
          label: book.name,
        });
        const chapterOptions: SelectOption[] = [];
        chapterOptions.push(ALL_OPTION);
        for (let chapter of book.chapters) {
          console.log(chapter);
          chapterOptions.push({
            value: chapter.id.toString(),
            label: chapter.name,
          });
        }
        allChapterOptionsVar[book.id.toString()] = chapterOptions;
      }
      setBookOptions([ALL_OPTION].concat(bookOptionsVar));
      setAllBookOptions(bookOptionsVar);
      setBookData(data.authorById.books);
      setAllChapterOptions(allChapterOptionsVar);
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  React.useEffect(() => {
    if (revenueData) {
      console.log("Revenue data");
      console.log(revenueData);
      const rev = revenueData!.revenue[0].sum / 100;
      const royalty = revenueData!.revenue[1].sum / 100;
      setRevenue(rev);
      setRoyalties(royalty);
    }
    if (revenueError) {
      console.log(revenueError);
    }
  }, [revenueData, revenueError]);

  React.useEffect(() => {
    if (authorPaymentData) {
      console.log("author payments data");
      console.log(authorPaymentData);
      setAuthorPayments(authorPaymentData.authorPayments)
    }
    if (authorPaymentError) {
      console.log(authorPaymentError);
    }
  }, [authorPaymentData, authorPaymentError]);

  React.useEffect(() => {
    if (revChartData) {
      console.log("Revenue Chart fetched = ");
      console.log(revChartData!.revenue);
      setRevenueHistory(revChartData!.revenue);
    }
    if (revChartError) {
      console.log(revChartError);
    }
  }, [revChartData, revChartError]);

  React.useEffect(() => {
    if (bvChartData) {
      console.log("BV Chart fetched = ");
      console.log(bvChartData!.bookViews);
      setBookViewHistory(bvChartData!.bookViews);
    }
    if (bvChartError) {
      console.log(bvChartError);
    }
  }, [bvChartData, bvChartError]);

  React.useEffect(() => {
    if (crChartData) {
      console.log("CR Chart fetched = ");
      console.log(crChartData!.chapterReads);
      setChapterReadHistory(crChartData!.chapterReads);
    }
    if (crChartError) {
      console.log(crChartError);
    }
  }, [crChartData, crChartError]);

  function handleBookChange(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setBookSelect(selectVal);
    if (selectVal.value === ALL) {
      setChapterOptions([ALL_OPTION]);
    } else {
      setChapterOptions(allChapterOptions![selectVal.value]);
    }
    console.log(`Book option selected:`, selectVal);
  };

  function handleChapterChange(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    if (selectVal.value === ALL) {
      setBookOptions([ALL_OPTION].concat(allBookOptions));
    } else {
      setBookOptions(allBookOptions);
    }
    setChapterSelect(selectVal);
    console.log(`Chapter option selected:`, selectVal);
  };

  function handlePeriodChange(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setPeriodSelect(selectVal);
    console.log(`Period option selected:`, selectVal);
  };

  React.useEffect(() => {
    function fetchMetrics(bookId: string, chapterId: string, period: string) {
      const bid = (bookId === ALL) ? undefined : Number(bookId);
      const cid = (chapterId === ALL) ? undefined : Number(chapterId);
      let dateTo: Date | undefined = new Date();
      let dateFrom: Date | undefined = new Date();
      let groupBy : string | undefined;

      // adjust date be 1-2 days earlier because we don't have real time amplitude data
      // need to wait for amplitude to aggregate data, and also for our script to fetch amplitude data
      dateTo.setUTCHours(23);
      // if (dateTo.getUTCHours() < 3) {
      //   dateTo.setDate(dateTo.getDate() - 2);
      // } else {
      //   dateTo.setDate(dateTo.getDate() - 1);
      // }

      if (period === "Doy") {
        console.log("Change to day");
        dateFrom.setDate(dateTo.getDate() - 1 * GRAPH_PERIODS);
        groupBy = "doy";
      } else if (period === "Week") {
        dateFrom.setDate(dateTo.getDate() - 7 * GRAPH_PERIODS);
        groupBy = "week";
      } else if (period === "Month") {
        dateFrom.setDate(dateTo.getDate() - 30 * GRAPH_PERIODS);
        groupBy = "month";
      } else if (period === "AllTime") {
        dateFrom = new Date(2021, 0, 1);
        groupBy = "month";
      }
      console.log("Fetch " + bid + " " + cid + " " + dateFrom);
      setRevenueHistory(null);
      setBookViewHistory((null));
      setChapterReadHistory((null));
      fetchRevChartData({
        variables: {
          id: author!.id,
          bookId: bid,
          chapterId: cid,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
      fetchBVChartData({
        variables: {
          id: author!.id,
          bookId: bid,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
      fetchCRChartData({
        variables: {
          id: author!.id,
          bookId: bid,
          chapterId: cid,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
    };

    // Load metric data on select change
    if (author && (bookSelect || chapterSelect || periodSelect)) {
      fetchMetrics(bookSelect.value, chapterSelect.value, periodSelect.value);
    }
  }, [author, bookSelect, chapterSelect, periodSelect, fetchRevChartData, fetchBVChartData, fetchCRChartData]);

  function renderRows() {
    return authorPayments!.map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.paidAt.toString().substring(0, 10)}
          </td>
          <td>
            {(o.grossRevenueMicros / 100).toFixed(2)}
          </td>
          <td>
            {(o.royaltiesMicros / 100).toFixed(2)}
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="DashboardContainer">
      <div className="DashboardSideBar">
        <SideBar/>
      </div>
      <div className="DashboardContent">
        <AdminHeader/>
        <div>
          <h2>Analytics</h2>
          <hr/>
          <h5>Overall Summary</h5>
          Earnings since your last payout.
          <br/>
          <br/>
          {revenue !== null && royalties !== null && author &&
            <Summary
              totalRevenue={revenue}
              totalRoyalties={royalties}
              author={author}
            />
          }
          {authorPayments && authorPayments.length > 0 &&
            <div>
              <hr/>
              <h5>Payout History</h5>
              <br/>
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Gross Revenue</th>
                  <th>Royalties Earned</th>
                </tr>
                </thead>
                <tbody>
                {renderRows()}
                </tbody>
              </Table>
            </div>
          }
          <hr/>
          <h5>Detailed Breakdown</h5>
          Breakdown your revenue, book views, and chapter reads by time period. Book views and chapter reads data is not real time and may take 1-2 days to appear.
          <br/><br/>
          {bookData && bookOptions &&
          <div>
            <p><b>Book</b>:</p>
            <Select
              value={bookSelect}
              onChange={handleBookChange}
              options={bookOptions}
            />
          </div>}
          <br/>
          {bookData && chapterOptions &&
          <div>
            <p><b>Chapter</b>:</p>
            <Select
              value={chapterSelect}
              onChange={handleChapterChange}
              options={chapterOptions}
            />
          </div>}
          <br/>
          {bookData &&
          <div>
            <p><b>Period</b>:</p>
            <Select
              value={periodSelect}
              onChange={handlePeriodChange}
              options={PERIOD_OPTIONS}
            />
          </div>}
          {bookData && bookSelect && chapterSelect
            && revenueHistory && bookViewHistory && chapterReadHistory &&
          <Metrics
            data={bookData}
            bookSelect={bookSelect}
            chapterSelect={chapterSelect}
            periodSelect={periodSelect}
            revenueHistory={revenueHistory}
            bookViewHistory={bookViewHistory}
            chapterReadHistory={chapterReadHistory}
          />
          }
        </div>
      </div>
    </div>
  );
}

// TODO: provide quarterly summaries
const Summary= ({
  totalRevenue,
  totalRoyalties,
  author
}: {
  totalRevenue: number,
  totalRoyalties: number,
  author: Author
}) => {
  // assume author is from US if they have not provided a country or provide US, otherwise assume international
  const international = (author._country === "United States" || author._country === null || author._country === undefined) ? false : true;
  const paypalFees = international ? 4.99 : 0.0299 * totalRoyalties;
  const payout = Math.max(totalRoyalties - paypalFees, 0);

  return (
    <div>
      <p><b>Total Revenue</b>: {totalRevenue.toFixed(2)}$</p>
      <p><b>Royalties</b>: {totalRoyalties.toFixed(2)}$</p>
      {/*<p><b>Estimated Platform Fees</b>: {platformFees.toFixed(2)}$</p>*/}
      <p><b>Estimated Paypal Fees</b>: {paypalFees.toFixed(2)}$</p>
      <p><b>Your Estimated Payout</b>: {payout.toFixed(2)}$</p>
      <p>Your estimated payout is royalties less paypal fees.</p>
    </div>
  );
};

const Metrics = ({
  data,
  bookSelect,
  chapterSelect,
  periodSelect,
  revenueHistory,
  bookViewHistory,
  chapterReadHistory
}: {
  data: BookData[],
  bookSelect: SelectOption,
  chapterSelect: SelectOption,
  periodSelect: SelectOption,
  revenueHistory: Array<MetricAggregation>,
  bookViewHistory: Array<MetricAggregation>,
  chapterReadHistory: Array<MetricAggregation>
}) => {
  // Determine if this is a free chapter; if so, no need to display revenue analytics
  function freeChapter() {
    if (bookSelect.value === ALL || chapterSelect.value === ALL) {
      return false;
    }
    const book = data.find((p: BookData) => p.id === Number(bookSelect.value));
    const chapter = book!.chapters.find((c: ChapterData) => c.id === Number(chapterSelect.value));
    return chapter!.beanPrice === 0;
  };
  function periodMetricSum(metrics: Array<MetricAggregation>, periodSelect: SelectOption) {
    if (periodSelect.value === "AllTime") {
      return metrics.reduce(function(prev, cur) {return prev + cur.sum;}, 0);
    } else {
      return metrics[metrics.length - 1].sum;;
    }
  }
  const isFreeChapter = freeChapter();
  const periodRevenue = periodMetricSum(revenueHistory, periodSelect);
  const periodBookViews = periodMetricSum(bookViewHistory, periodSelect);
  const periodChapterReads = periodMetricSum(chapterReadHistory, periodSelect);;

  return (
    <div>
      <div>
        <br/>
        { isFreeChapter &&
          <p style={{color:"green"}}>
            <b>Free Chapter</b>
          </p>
        }
        {!isFreeChapter &&
        <div>
          <p><b>Revenue</b>: {(periodRevenue / 100).toFixed(2)}$</p>
          <MetricOverTime
            chartData={revenueHistory}
            metricName={"Revenue"}
            dataMultiplier={0.01}
          />
        </div>
        }
        <p><b>Book Views</b>: {periodBookViews}</p>
        <MetricOverTime
          chartData={bookViewHistory}
          metricName={"Book Views"}
        />
        <p><b>Chapter Reads</b>: {periodChapterReads}</p>
        <MetricOverTime
          chartData={chapterReadHistory}
          metricName={"Chapter Reads"}
        />
      </div>
    </div>
  );
};

export const MetricOverTime = ({
  chartData,
  metricName,
  dataMultiplier=1
}: {
  chartData: Array<MetricAggregation>,
  metricName: string,
  dataMultiplier?: number
}) => {
  console.log(`Chart for ${metricName}`);
  console.log(chartData);

  const dataLength = chartData.length;
  const data = chartData.slice(dataLength - GRAPH_PERIODS, dataLength).map((ra : MetricAggregation) => {
    let options: {year?: 'numeric', month?: 'long', day?: 'numeric'};
    let date: Date;
    let key: string = "";
    if (ra.doy !== null) {
      date = new Date(ra.year!, 0, ra.doy!);
      options = { year: 'numeric', month: 'long', day: 'numeric' };
    } else if (ra.week !== null) {
      const doy = ra.week! * 7;
      date = new Date(ra.year!, 0, doy);
      options = { year: 'numeric', month: 'long', day: 'numeric' };
    } else if (ra.month !== null) {
      date = new Date(ra.year!, ra.month! - 1);
      options = { year: 'numeric', month: 'long' };
    }
    key = date!.toLocaleDateString("en-US", options!);
    console.log(key);

    return {
      name: key,
      [metricName]: ra.sum * dataMultiplier,
    }
  });

  return (
    <LineChart
      width={650}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 45,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" angle={-5} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey={metricName} stroke="#82ca9d" />
    </LineChart>
  );
}
