import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
// @ts-ignore
import Select from 'react-select';
import {Carousel, SelectOption} from "../Types";
import AdminHeader from "../components/AdminHeader";
import Table from "react-bootstrap/Table";
import FormSelect from "../components/FormSelect";

interface CreateCarouselInput {
  name: string;
  displayAndroid: boolean;
  displayIos: boolean;
  hidden: boolean;
}

interface UpdateCarouselInput {
  id: number;
  name: string;
  displayAndroid: boolean;
  displayIos: boolean;
  hidden: boolean;
}

interface DeleteCarouselItemInput {
  position: number;
  carouselId: number;
}

interface CreateCarouselItemInput {
  position: number;
  bookId: number | null;
  carouselId: number;
  genreId: number | null;
  imageUrl: string | null;
  type: string;
}

interface FetchCarouselData {
  carousels: Array<Carousel>;
}

const CAROUSEL_QUERY = `
id
name
displayAndroid
displayIos
hidden
`;

const CAROUSEL_ITEM_QUERY = `
position
book {
  id
  name
}
genre {
  displayName
}
imageUrl
type
`;

const CREATE_CAROUSEL = gql`
  mutation CreateCarousel($data: CreateCarouselInput!) {
    createCarousel(data: $data) {
      ${CAROUSEL_QUERY}
    }
  }
`;

const DELETE_CAROUSEL = gql`
  mutation DeleteCarousel($carouselId: Int!) {
    deleteCarousel(carouselId: $carouselId) {
      ${CAROUSEL_QUERY}
    }
  }
`;

const UPDATE_CAROUSEL = gql`
  mutation UpdateCarousel($data: UpdateCarouselInput!) {
    updateCarousel(data: $data) {
      ${CAROUSEL_QUERY}
    }
  }
`;

const CREATE_CAROUSEL_ITEM = gql`
  mutation CreateCarouselItem($data: CreateCarouselItemInput!) {
    createCarouselItem(data: $data) {
      ${CAROUSEL_ITEM_QUERY}
    }
  }
`;

const DELETE_CAROUSEL_ITEM = gql`
  mutation DeleteCarousel($data: DeleteCarouselItemInput!) {
    deleteCarouselItem(data: $data)
  }
`;

export const ALL_CAROUSEL = gql`
  query FetchCarousels {
    carousels {
      ${CAROUSEL_QUERY}   
    }
  }
`;

const CAROUSEL_DETAILED_QUERY = `
id
name
hidden
displayAndroid
displayIos
carouselItems {
  ${CAROUSEL_ITEM_QUERY}
}
`;

const GENRE_QUERY = `
id
displayName
`;

interface BookMetadata {
  id: number;
  name: string;
}

interface GenreMetadata {
  id: number;
  displayName: string;
}

interface CarouselItem {
  position: number;
  book: BookMetadata | null;
  genre: GenreMetadata | null;
  imageUrl: string | null;
  type: string;
}

interface CarouselDetailed {
  id: number;
  name: string;
  displayAndroid: boolean;
  displayIos: boolean;
  hidden: boolean;
  carouselItems: Array<CarouselItem>;
}

interface FetchCarouselDetailed {
  carousels: Array<CarouselDetailed>;
}

export const FETCH_CAROUSEL_DETAILED = gql`
  query FetchCarouselDetailed($carouselId: Int!) {
    carousels(carouselId: $carouselId) {
      ${CAROUSEL_DETAILED_QUERY}
    }
  }
`;

interface FetchBookData {
  allBooks: Array<BookMetadata>;
}

interface FetchGenreData {
  genres: Array<GenreData>;
}

interface GenreData {
  id: number;
  displayName: string;
}

export const ALL_BOOKS_FRAGMENT = `
allBooks {
  id
  name
}
`;

export const ALL_GENRES_FRAGMENT = `
genres {
  ${GENRE_QUERY}
}
`;

export const ALL_BOOKS = gql`
  query FetchBooks {
    ${ALL_BOOKS_FRAGMENT}
  }
`;

export const ALL_GENRES = gql`
  query FetchGenres {
    ${ALL_GENRES_FRAGMENT}
  }
`;

// TODO: use dropdown instead of email field for easier author selection
export function AdminCarouselPage () {
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);
  const [bookOptions, setBookOptions] = useState<SelectOption[]>([]);
  const [genreOptions, setGenreOptions] = useState<SelectOption[]>([]);
  const [positionOptions, setPositionOptions] = useState<SelectOption[]>([]);
  const [carouselOptions, setCarouselOptions] = useState<SelectOption[]>([]);
  const [selectedCarousel, setSelectedCarousel] = useState<SelectOption | null>(null);
  const [selectedBook, setSelectedBook] = useState<SelectOption | null>(null);
  const [selectedGenre, setSelectedGenre] = useState<SelectOption | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<SelectOption | null>(null);
  const [newCarouselHidden, setNewCarouselHidden] = useState(false);
  const [carouselHidden, setCarouselHidden] = useState(false);
  const [newCarouselName, setNewCarouselName] = useState("");
  const [carouselDisplayAndroid, setCarouselDisplayAndroid] = useState(true);
  const [newCarouselDisplayAndroid, setNewCarouselDisplayAndroid] = useState(true);
  const [carouselDisplayIos, setCarouselDisplayIos] = useState(true);
  const [newCarouselDisplayIos, setNewCarouselDisplayIos] = useState(true);
  const carouselItemTypeMapping : Map<string, string> = new Map<string, string>([["book", "Book"], ["genre", "Genre"], ["coffee_bean", "Coffee Bean"]])
  const [newCarouselTypeOptions] = useState<SelectOption[]>([
    {value: "book", label: carouselItemTypeMapping.get("book")!},
    {value: "genre", label: carouselItemTypeMapping.get("genre")!},
    {value: "coffee_bean", label: carouselItemTypeMapping.get("coffee_bean")!}
  ]);
  const [newCarouselType, setNewCarouselType] = useState<SelectOption | null>({value: "book", label: "Book"});
  const [newCarouselImageUrl, setNewCarouselImageUrl] = useState("");
  const [carouselName, setCarouselName] = useState("");
  const {data: bookData} = useQuery<FetchBookData>(ALL_BOOKS);
  const {data: genreData} = useQuery<FetchGenreData>(ALL_GENRES);
  const [createCarouselGraphql] = useMutation<
    {createCarousel: Carousel},
    {data: CreateCarouselInput}
    >(CREATE_CAROUSEL);
  const [deleteCarouselGraphql] = useMutation<
    {deleteCarousel: Carousel},
    {carouselId: number}
    >(DELETE_CAROUSEL);
  const [updateCarouselGraphql] = useMutation<
    {updateCarousel: Carousel},
    {data: UpdateCarouselInput}
    >(UPDATE_CAROUSEL);
  const [createCarouselItemGraphql] = useMutation<
    {createCarouselItem: CarouselItem},
    {data: CreateCarouselItemInput}
    >(CREATE_CAROUSEL_ITEM);
  const [deleteCarouselItemGraphql] = useMutation<
    {deleteCarouselItem: boolean},
    {data: DeleteCarouselItemInput}
    >(DELETE_CAROUSEL_ITEM);
  const [fetchCarouselDetailed, {data: carouselDetailedData, error:carouselDetailedError}] = useLazyQuery<FetchCarouselDetailed>(FETCH_CAROUSEL_DETAILED, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const [fetchCarouselData, {data: carouselData, error:carouselError}] = useLazyQuery<FetchCarouselData>(ALL_CAROUSEL, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const [carouselDetailed, setCarouselDetailed] = useState<CarouselDetailed | null>(null);

  React.useEffect(() => {
    if (fetchCarouselData) {
      fetchCarouselData();
    }
  }, [fetchCarouselData]);

  React.useEffect(() => {
    if (carouselData) {
      console.log("carousel data");
      console.log(carouselData);
      const options: SelectOption[] = [];
      for (let carousel of carouselData.carousels) {
        options.push({
          value: carousel.id.toString(),
          label: carousel.name,
        });
      }
      setCarouselOptions(options);
    }
    if (carouselError) {
      console.log(carouselError);
    }
  }, [carouselData, carouselError]);

  React.useEffect(() => {
    if (bookData) {
      console.log(bookData);
      const options: SelectOption[] = [];
      for (let book of bookData.allBooks) {
        options.push({
          value: book.id.toString(),
          label: book.name,
        });
      }
      setBookOptions(options);
    }
  }, [bookData]);

  React.useEffect(() => {
    if (genreData) {
      console.log(genreData);
      const options: SelectOption[] = [];
      for (let genre of genreData.genres) {
        options.push({
          value: genre.id.toString(),
          label: genre.displayName,
        });
      }
      setGenreOptions(options);
    }
  }, [genreData]);

  function validateCreateCarouselForm() {
    return newCarouselName.length > 0;
  }

  function handleSelectCarousel(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelectedCarousel(selectVal);
    fetchCarouselDetailed({
      variables: {carouselId: Number(selectVal.value)},
    });
    console.log(`Option selected:`, selectVal);
  };

  function handleSelectNewCarouselType(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    if (selectVal.value === "book") {
      setSelectedGenre(null);
    } else if (selectVal.value === "genre") {
      setSelectedBook(null);
    } else if (selectVal.value === "coffee_bean") {
      setSelectedGenre(null);
      setSelectedBook(null);
    }
    setNewCarouselType(selectVal);
  };

  function handleSelectBook(selectVal: SelectOption | null) {
    if (!selectVal) {
      setSelectedBook(null);
      return;
    }
    setSelectedBook(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  function handleSelectGenre(selectVal: SelectOption | null) {
    if (!selectVal) {
      setSelectedGenre(null);
      return;
    }
    setSelectedGenre(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  function handleSelectPosition(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelectedPosition(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  function handleCheckNewCarouselHidden(e: any) {
    console.log(e.target.checked);
    setNewCarouselHidden(e.target.checked);
  }

  function handleCheckUpdateCarouselHidden(e: any) {
    console.log(e.target.checked);
    setCarouselHidden(e.target.checked);
  }

  function handleCheckNewCarouselDisplayAndroid(e: any) {
    console.log(e.target.checked);
    setNewCarouselDisplayAndroid(e.target.checked);
  }

  function handleCheckUpdateCarouselDisplayAndroid(e: any) {
    console.log(e.target.checked);
    setCarouselDisplayAndroid(e.target.checked);
  }

  function handleCheckNewCarouselDisplayIos(e: any) {
    console.log(e.target.checked);
    setNewCarouselDisplayIos(e.target.checked);
  }

  function handleCheckUpdateCarouselDisplayIos(e: any) {
    console.log(e.target.checked);
    setCarouselDisplayIos(e.target.checked);
  }

  React.useEffect(() => {
    if (carouselDetailedData) {
      console.log('------------received carousel data detailed');
      console.log(carouselDetailedData);
      if (carouselDetailedData.carousels.length === 0) {
        return;
      }
      setCarouselDetailed(carouselDetailedData.carousels[0]);
      setCarouselName(carouselDetailedData.carousels[0].name);
      setCarouselDisplayAndroid(carouselDetailedData.carousels[0].displayAndroid);
      setCarouselDisplayIos(carouselDetailedData.carousels[0].displayIos);
      setCarouselHidden(carouselDetailedData.carousels[0].hidden);
    }
    if (carouselDetailedError) {
      console.log(carouselDetailedError);
    }
  }, [carouselDetailedData, carouselDetailedError]);

  React.useEffect(() => {
    if (carouselDetailed) {
      setCarouselItems(carouselDetailed.carouselItems);
      const numCarousels = carouselDetailed.carouselItems.length;
      console.log(carouselDetailed);
      const options: SelectOption[] = [];
      for (let i = 0; i < numCarousels + 1; i++) {
        options.push({
          value: Number(i+1).toString(),
          label: Number(i+1).toString(),
        });
      }
      setPositionOptions(options);
    }
  }, [carouselDetailed]);

  async function handleCreateCarousel() {
    console.log("create carousel");
    await createCarouselGraphql({
      variables: {
        data: {
          name: newCarouselName,
          displayAndroid: newCarouselDisplayAndroid,
          displayIos: newCarouselDisplayIos,
          hidden: newCarouselHidden,
        },
      },
    }).then(() => {
      createToast("Carousel created.");
      fetchCarouselData();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  function renderRows() {
    return carouselItems.map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.position}
          </td>
          <td>
            {carouselItemTypeMapping.get(o.type)}
          </td>
          <td>
            {o.book ? o.book.name : null}
          </td>
          <td>
            {o.genre ? o.genre.displayName : null}
          </td>
          <td>
            {o.imageUrl}
          </td>
          <td>
            <LoginButton
              text="Delete"
              type="button"
              disabled={false}
              onClick={() => deleteCarouselItem(i)}
            />
          </td>
        </tr>
      );
    });
  }

  function validateAddCarouselItem() {
    return selectedPosition && newCarouselType &&
      ((newCarouselType.value === 'book' && selectedBook && !selectedGenre)
      || (newCarouselType.value === 'genre' && selectedGenre && !selectedBook)
      || (newCarouselType.value === 'coffee_bean' && !selectedBook && !selectedGenre));
  }

  async function handleAddCarouselItem() {
    console.log("add carousel item");
    console.log(newCarouselType);
    await createCarouselItemGraphql({
      variables: {
        data: {
          position: Number(selectedPosition!.value),
          bookId: selectedBook ? Number(selectedBook!.value) : null,
          carouselId: carouselDetailed!.id,
          genreId: selectedGenre ? Number(selectedGenre!.value) : null,
          imageUrl: newCarouselImageUrl,
          type: newCarouselType!.value,
        },
      },
    }).then(() => {
      createToast("Carousel item created.");
      fetchCarouselDetailed({
        variables: {carouselId: Number(selectedCarousel!.value)},
      });
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  async function updateCarousel() {
    console.log("update carousel");
    await updateCarouselGraphql({
      variables: {
        data: {
          id: Number(selectedCarousel!.value),
          name: carouselName,
          displayAndroid: carouselDisplayAndroid,
          displayIos: carouselDisplayIos,
          hidden: carouselHidden,
        },
      },
    }).then(() => {
      createToast("Carousel updated.");
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  function validateUpdateCarousel() {
    return carouselName.length > 0;
  }

  async function deleteCarouselItem(i: number) {
    const responseData = await deleteCarouselItemGraphql({
      variables: {
        data: {
          position: Number(carouselItems[i].position),
          carouselId: carouselDetailed!.id,
        }
      },
    }).then(() => {
      createToast("Carousel item deleted.");
      fetchCarouselDetailed({
        variables: {carouselId: Number(selectedCarousel!.value)},
      });
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function deleteCarousel() {
    console.log("create carousel");
    await deleteCarouselGraphql({
      variables: {
        carouselId: Number(selectedCarousel!.value)
      },
    }).then(() => {
      createToast("Carousel deleted.");
      setSelectedCarousel(null);
      setSelectedBook(null);
      setSelectedPosition(null);
      setCarouselDetailed(null);
      fetchCarouselData();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Panel</h2>
          <hr/>
          <h5>
            Manage carousels as an admin.
          </h5>
          <hr/>
          <h5><b>Carousels</b>:</h5>
          <Card>
            <Card.Header>Create Carousel</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleCreateCarousel();
              }}>
                <Form.Group style={{
                  marginBottom: '10px',
                  textAlign: 'left',
                }}>
                  <Form.Label>
                    Name
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={newCarouselName}
                    rows={1}
                    type={"text"}
                    onChange={(e) => setNewCarouselName(e.target.value)}
                    placeholder={"Name"}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Display Android"
                    checked={newCarouselDisplayAndroid}
                    onChange={handleCheckNewCarouselDisplayAndroid}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Display Ios"
                    checked={newCarouselDisplayIos}
                    onChange={handleCheckNewCarouselDisplayIos}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Hide Carousel"
                    checked={newCarouselHidden}
                    onChange={handleCheckNewCarouselHidden}
                  />
                </Form.Group>
                <LoginButton
                  text="Create"
                  type="submit"
                  disabled={!validateCreateCarouselForm()}
                />
              </Form>
            </Card.Body>
          </Card>
          <hr/>
          {!selectedCarousel &&
          <p>Please select a carousel to manage.</p>
          }
          {carouselOptions &&
          <Select
            value={selectedCarousel}
            onChange={handleSelectCarousel}
            options={carouselOptions}
          />}
          {selectedCarousel && carouselDetailed &&
            <div>
              <br/>
              <Card className="CreateCoupon">
                <Card.Header>Update Carousel</Card.Header>
                <Card.Body>
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    // handleUpdate();
                  }}>
                    <Form.Group style={{
                      marginBottom: '10px',
                      textAlign: 'left',
                    }}>
                      <Form.Label>
                        Name
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        value={carouselName}
                        rows={1}
                        type={"text"}
                        onChange={(e) => setCarouselName(e.target.value)}
                        placeholder={"Name"}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Display Android"
                        checked={carouselDisplayAndroid}
                        onChange={handleCheckUpdateCarouselDisplayAndroid}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Display Ios"
                        checked={carouselDisplayIos}
                        onChange={handleCheckUpdateCarouselDisplayIos}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Hide Carousel"
                        checked={carouselHidden}
                        onChange={handleCheckUpdateCarouselHidden}
                      />
                    </Form.Group>
                    <LoginButton
                      text="Update"
                      type="button"
                      disabled={!validateUpdateCarousel()}
                      onClick={updateCarousel}
                    />
                    <LoginButton
                      text="Delete Carousel"
                      type="button"
                      disabled={false}
                      onClick={deleteCarousel}
                    />
                  </Form>
                </Card.Body>
              </Card>
            </div>
          }
          {selectedCarousel && bookOptions &&
          <div>
            <br></br>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Position</th>
                <th>Type</th>
                <th>Book</th>
                <th>Genre</th>
                <th>Image Url</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {carouselItems && renderRows()}
              </tbody>
            </Table>
            <hr/>
            <Card className="CreateCoupon">
              <Card.Header>Add Carousel Item to Carousel</Card.Header>
              <Card.Body>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  // handleUpdate();
                }}>
                  <Form.Label>
                    Carousel Item Type
                  </Form.Label>
                  <Select
                    value={newCarouselType}
                    onChange={handleSelectNewCarouselType}
                    options={newCarouselTypeOptions}
                  />
                  <FormSelect
                    fieldName={"Position"}
                    options={positionOptions}
                    onChange={handleSelectPosition}
                    value={selectedPosition}
                    multiSelect={false}
                  />
                  <FormSelect
                    fieldName={"Book"}
                    options={bookOptions}
                    onChange={handleSelectBook}
                    value={selectedBook}
                    multiSelect={false}
                    isClearable={true}
                  />
                  <FormSelect
                    fieldName={"Genre"}
                    options={genreOptions}
                    onChange={handleSelectGenre}
                    value={selectedGenre}
                    multiSelect={false}
                    isClearable={true}
                  />
                  <Form.Label>
                    Image Url
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={newCarouselImageUrl}
                    rows={1}
                    type={"text"}
                    onChange={(e) => setNewCarouselImageUrl(e.target.value)}
                    placeholder={"Image Url"}
                  />
                  <LoginButton
                    text="Add Carousel Item"
                    type="button"
                    disabled={!validateAddCarouselItem()}
                    onClick={handleAddCarouselItem}
                  />
                </Form>
              </Card.Body>
            </Card>
          </div>
          }
        </div>
      </div>
    </div>
  );
};
