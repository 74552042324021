import React, {ChangeEvent} from 'react';
import Form from "react-bootstrap/Form";

const FormInput = ({
  fieldName,
  value,
  onChangeText,
  type,
  rows=1,
  disabled=false,
}: {
  fieldName: string;
  value: string;
  onChangeText: (event:  ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  type: string,
  rows?: number,
  disabled?: boolean,
}) => {
  return (
    <Form.Group style={{
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: 'left',
    }}>
      <Form.Label>
        {fieldName}
      </Form.Label>
      <Form.Control
        as="textarea"
        value={value}
        rows={rows}
        type={type}
        onChange={onChangeText}
        placeholder={fieldName}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default FormInput;
