import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {gql, useMutation} from '@apollo/client';
import '../App.css';
import LoginInput from "../components/LoginInput";
import LoginButton from "../components/LoginButton";
import FormTextButton from "../components/FormTextButton";
import WebToastContainer from "../components/WebToastContainer";
import {useHistory} from "react-router-dom";
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import {createToast} from "../components/WebToast";

const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(email: $email)
    }
`;

interface ResetPasswordInput {
  email: string;
}

export function ResetPasswordPage() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [resetPassword, {error: mutationError}] = useMutation<
    boolean,
    ResetPasswordInput
    >(RESET_PASSWORD);

  React.useEffect(() => {
    if (mutationError !== undefined) {
      console.log(mutationError);
    }
  }, [mutationError]);

  function handleResetPassword(email: string) {
    resetPassword({
      variables: {
        email: email,
      },
    }).then(() => {
      createToast(`Password reset information sent to ${email}`);
    })
  }

  function validateForm() {
    return email.length > 0;
  }

  function toLogin() {
    console.log("Login");
    history.push("/login");
  }

  return (
    <div>
      <WebToastContainer/>
      <Card className="Login">
        <Card.Header>Reset Password</Card.Header>
        <Card.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleResetPassword(email);
          }}>
            <LoginInput
              placeholder="Email"
              type="email"
              onChangeText={(e) => setEmail(e.target.value)}
            />
            <LoginButton
              text="Submit"
              type="submit"
              disabled={!validateForm()}
            />
            <FormTextButton onClick={toLogin} text={"Login"}/>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
