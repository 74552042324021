import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
// @ts-ignore
import Select from 'react-select';
import {SelectOption, Genre} from "../Types";
import AdminHeader from "../components/AdminHeader";
import {ImageType} from "react-images-uploading";
import FormUploadImage from "../components/FormUploadImage";

interface CreateGenreInput {
  label: string;
  displayName: string;
  file?: File | null | undefined;
}

interface UpdateGenreInput {
  id: number;
  displayName: string;
  file?: File | null | undefined;
}

const GENRE_QUERY = `
id
label
displayName
imageUrl
`;

const CREATE_GENRE = gql`
  mutation CreateGenre($data: CreateGenreInput!) {
    createGenre(data: $data) {
      ${GENRE_QUERY}
    }
  }
`;

const DELETE_GENRE = gql`
  mutation DeleteGenre($genreId: Int!) {
    deleteGenre(genreId: $genreId) {
      ${GENRE_QUERY}
    }
  }
`;

const UPDATE_GENRE = gql`
  mutation UpdateGenre($data: UpdateGenreInput!) {
    updateGenre(data: $data) {
      ${GENRE_QUERY}
    }
  }
`;

interface FetchGenreData {
  genres: Array<Genre>;
}

export const ALL_GENRES_FRAGMENT = `
genres {
  ${GENRE_QUERY}
}
`;

export const ALL_GENRES = gql`
  query FetchGenres {
    ${ALL_GENRES_FRAGMENT}
  }
`;

// TODO: use dropdown instead of email field for easier author selection
export function AdminGenrePage () {
  const [genreDataMap, setGenreDataMap] = useState<Map<string, Genre> | null>(null);
  const [genreOptions, setGenreOptions] = useState<SelectOption[]>([]);
  const [selectedGenre, setSelectedGenre] = useState<SelectOption | null>(null);
  const [newGenreName, setNewGenreName] = useState("");
  const [newImage, setNewImage] = useState<Array<ImageType>>([]);
  const [genreImage, setGenreImage] = useState<Array<ImageType>>([]);
  const [genreName, setGenreName] = useState("");
  const [genreImageUrl, setGenreImageUrl] = useState<string | undefined>(undefined);
  const [createGenreGraphql] = useMutation<
    {createGenre: Genre},
    {data: CreateGenreInput}
    >(CREATE_GENRE);
  const [deleteGenreGraphql] = useMutation<
    {deleteGenre: Genre},
    {genreId: number}
    >(DELETE_GENRE);
  const [updateGenreGraphql] = useMutation<
    {updateGenre: Genre},
    {data: UpdateGenreInput}
    >(UPDATE_GENRE);
  const [fetchGenreData, {data: genreData, error:genreDataError}] = useLazyQuery<FetchGenreData>(ALL_GENRES, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });

  React.useEffect(() => {
    if (fetchGenreData) {
      fetchGenreData();
    }
  }, [fetchGenreData]);

  React.useEffect(() => {
    if (genreData) {
      console.log(genreData);
      const options: SelectOption[] = [];
      for (let genre of genreData.genres) {
        options.push({
          value: genre.id.toString(),
          label: genre.displayName,
        });
      }
      setGenreDataMap(new Map(genreData.genres.map(genre => [genre.id.toString(), genre])));
      setGenreOptions(options);
    }
    if (genreDataError) {
      console.log(genreDataError);
    }
  }, [genreData, genreDataError]);

  function validateCreateGenreForm() {
    return newGenreName.length > 0;
  }

  function handleSelectGenre(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelectedGenre(selectVal);
    setGenreName(selectVal.label);
    setGenreImageUrl(genreDataMap!.get(selectVal.value)!.imageUrl);
    setGenreImage([]);
    console.log(`Option selected:`, selectVal);
  }

  async function handleCreateGenre() {
    // create unique label
    const suffix = Math.random()
      .toString(36)
      .substr(2, 6)
      .toUpperCase()
    const genreLabel = `${newGenreName.replace(/\s/g, "")}_${suffix};`
    await createGenreGraphql({
      variables: {
        data: {
          label: genreLabel,
          displayName: newGenreName,
          file: newImage.length === 1 ? newImage[0].file : null,
        },
      },
    }).then(() => {
      createToast("Genre created.");
      fetchGenreData();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  async function updateGenre() {
    await updateGenreGraphql({
      variables: {
        data: {
          id: Number(selectedGenre!.value),
          displayName: genreName,
          file: genreImage.length === 1 ? genreImage[0].file : null,
        },
      },
    }).then(() => {
      createToast("Genre updated.");
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  function validateUpdateGenre() {
    return genreName.length > 0;
  }

  async function deleteGenre() {
    await deleteGenreGraphql({
      variables: {
        genreId: Number(selectedGenre!.value)
      },
    }).then(() => {
      createToast("Genre deleted.");
      setSelectedGenre(null);
      fetchGenreData();
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
  }

  const onUploadNewImage = (imageList: ImageType[], addUpdateIndex: number[] | undefined) => {
    console.log(imageList, addUpdateIndex);
    setNewImage(imageList);
  };

  const onUploadGenreImage = (imageList: ImageType[], addUpdateIndex: number[] | undefined) => {
    console.log(imageList, addUpdateIndex);
    setGenreImage(imageList);
  };

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Panel</h2>
          <hr/>
          <h5>
            Manage genres as an admin.
          </h5>
          <hr/>
          <h5><b>Genres</b>:</h5>
          <Card>
            <Card.Header>Create Genre</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleCreateGenre();
              }}>
                <div>
                  {/*eslint-disable-next-line*/}
                  <img
                    src={(newImage.length === 1) ? newImage[0].data_url : null}
                    alt={"No image provided"}
                    height={"30%"}
                    width={"30%"}
                    className="center"
                  />
                </div>
                  <Form.Label>
                    Name
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={newGenreName}
                    rows={1}
                    type={"text"}
                    onChange={(e) => setNewGenreName(e.target.value)}
                    placeholder={"Name"}
                  />
                <FormUploadImage
                  fieldName="Genre Image"
                  images={newImage}
                  onChange={onUploadNewImage}
                  maxNumber={1}
                />
                <LoginButton
                  text="Create"
                  type="submit"
                  disabled={!validateCreateGenreForm()}
                />
              </Form>
            </Card.Body>
          </Card>
          <hr/>
          {!selectedGenre &&
          <p>Please select a genre to manage.</p>
          }
          {genreOptions && genreDataMap &&
          <Select
            value={selectedGenre}
            onChange={handleSelectGenre}
            options={genreOptions}
          />}
          {selectedGenre &&
            <div>
              <br/>
              <Card className="CreateCoupon">
                <Card.Header>Update Genre</Card.Header>
                <Card.Body>
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    // handleUpdate();
                  }}>
                    <Form.Group style={{
                      marginBottom: '10px',
                      textAlign: 'left',
                    }}>
                      <div>
                        {/*eslint-disable-next-line*/}
                        <img
                          src={(genreImage.length === 1) ? genreImage[0].data_url : genreImageUrl}
                          alt={"No image provided"}
                          height={"30%"}
                          width={"30%"}
                          className="center"
                        />
                      </div>
                      <Form.Label>
                        Name
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        value={genreName}
                        rows={1}
                        type={"text"}
                        onChange={(e) => setGenreName(e.target.value)}
                        placeholder={"Name"}
                      />
                    </Form.Group>
                    <LoginButton
                      text="Update"
                      type="button"
                      disabled={!validateUpdateGenre()}
                      onClick={updateGenre}
                    />
                    <FormUploadImage
                      fieldName="Genre Image"
                      images={genreImage}
                      onChange={onUploadGenreImage}
                      maxNumber={1}
                    />
                    <LoginButton
                      text="Delete Genre"
                      type="button"
                      disabled={false}
                      onClick={deleteGenre}
                    />
                  </Form>
                </Card.Body>
              </Card>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
