import React, {ReactNode} from 'react';
import {Author, AuthorRegistration, User} from '../Types';
import {gql, useMutation, useQuery} from '@apollo/client';
import _ from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';

// TODO: cleanup unused stuff
export const ACCESS_TOKEN_KEY = '@access_token';
const USER_DATA_KEY = '@user_data';
const AUTHOR_DATA_KEY = '@author_data';
const AUTHOR_REGISTRATION_DATA_KEY = '@author_registration_data';

const AUTHOR_FRAGMENT = `
id
alias
name
description
twitterHandle
instagramHandle
personalWebsite
profilePicUrl
_legalName
_entityName
_ein
_paypalEmail
_country`;

const AUTHOR_REGISTRATION_FRAGMENT = `
id
userId
name
email
additionalInfo
status`;

export const USER_FRAGMENT = `
id
isAdmin
username
name
email
profilePictureThumbnailUrl
onboardingComplete
isAuthor
viewMature
connectedAuthorId
connectedAuthor {
  ${AUTHOR_FRAGMENT}
}
authorRegistration {
  ${AUTHOR_REGISTRATION_FRAGMENT}
}`;

const LOGIN_FRAGMENT = `
accessToken
user {
  ${USER_FRAGMENT}
}`;

const LOGIN_USER = gql`
  mutation($data: UserLoginInput!) {
    login(data: $data) {
      ${LOGIN_FRAGMENT}
    }
  }
`;

const LOGIN_APPLE = gql`
  mutation($identityToken: String!, $clientId: String!) {
    loginApple(identityToken: $identityToken, clientId: $clientId) {
      ${LOGIN_FRAGMENT}
    }
  }
`;

export const FETCH_AUTHOR = gql`
  query FetchAuthorById($id: String!) {
    authorById(id: $id) {
      ${AUTHOR_FRAGMENT}
    }
  }
`;

const REGISTER_AUTHOR = gql`
  mutation RegisterAuthor($name: String!, $email: String!, $additionalInfo: String!) {
    registerAuthor(name: $name, email: $email, additionalInfo: $additionalInfo) {
      ${AUTHOR_REGISTRATION_FRAGMENT}
    }
  }
`;

interface UserLoginInput {
  email: string;
  password: string;
}

interface UserAppleLoginInput {
  identityToken: string;
  clientId: string;
}

interface UserData {
  id: number;
  isAdmin: boolean;
  username: string;
  name: string;
  email: string;
  profilePictureThumbnailUrl?: string;
  onboardingComplete: boolean;
  isAuthor: boolean;
  viewMature: boolean;
  connectedAuthor?: Author;
  authorRegistration?: AuthorRegistration;
}

interface UserLoginData {
  accessToken: string;
  user: UserData;
}

const CREATE_USER = gql`
  mutation($data: UserCreateInput!) {
    signupUser(data: $data) {
      accessToken
      user {
        ${USER_FRAGMENT}
      }
    }
  }
`;

export enum CreateUserResult {
  SUCCESS = 'login',
  EMAIL_IN_USE = 'email_in_use',
  USERNAME_IN_USE = 'username_in_use',
  OTHER_ERROR = 'other_error',
}

interface CreateUserInput {
  email: string;
  username: string;
  password: string;
}

interface CreateUserLoginData {
  accessToken: string;
  user: UserData;
}

const UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput!) {
    updateUser(data: $data) {
      ${USER_FRAGMENT}
    }
  }
`;

interface UserUpdateInput {
  email?: string;
  username?: string;
  name?: string;
  allowMature?: boolean;
}

const UPDATE_AUTHOR = gql`
  mutation UpdateAuthor($data: AuthorUpdateInput!) {
    updateAuthor(data: $data) {
      ${AUTHOR_FRAGMENT}
    }
  }
`;

interface AuthorUpdateInput {
  id: number;
  alias?: string;
  name?: string;
  description?: string;
  twitterHandle?: string;
  instagramHandle?: string;
  personalWebsite?: string;
  file?: File | null | undefined;
}

const SET_AUTHOR_PAYMENT = gql`
  mutation UpdateAuthor($data: AuthorPaymentInput!) {
    setAuthorPayment(data: $data) {
      ${AUTHOR_FRAGMENT}
    }
  }
`;

interface AuthorPaymentInput {
  id: number;
  legalName: string;
  entityName: string;
  ein: string;
  paypalEmail: string;
  country: string;
}

type AuthContextType = {
  accessToken?: string;
  user?: User | null;
  adminAuthor?: Author | null;
  adminLoginAsAuthor: boolean;
  author?: Author | null;
  authorRegistration?: AuthorRegistration | null;
  login: (username: string, password: string) => Promise<boolean>;
  loginApple: (identityToken: string) => Promise<void>;
  loginAsAuthor: (id: string) => Promise<void>;
  createUser: (
    email: string,
    username: string,
    password: string,
  ) => Promise<CreateUserResult>;
  registerAuthor: (
    name: string,
    email: string,
    additionalInfo: string
  ) => Promise<void>;
  createUserInProgress: boolean;
  updateUser: (data: UserUpdateInput) => Promise<void>;
  updateAuthor: (data: AuthorUpdateInput) => Promise<void>;
  setAuthorPayment: (data: AuthorPaymentInput) => Promise<void>;
  updateUserInProgress: boolean;
  logout: () => Promise<void>;
  returnToAdmin: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const useAuthContext = (): AuthContextType => {
  const value = React.useContext<AuthContextType | undefined>(AuthContext);
  if (value === undefined) {
    throw new Error('Did you use a provider?');
  }
  return value;
};

const AuthContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [accessToken, setAccessToken] = React.useState<string>();
  const [user, setUser] = React.useState<User | null>();
  const [adminAuthor, setAdminAuthor] = React.useState<Author | null>();
  const [adminLoginAsAuthor, setAdminLoginAsAuthor] = React.useState<boolean>(false);
  const [author, setAuthor] = React.useState<Author | null>();
  const [authorRegistration, setAuthorRegistration] = React.useState<AuthorRegistration | null>();

  React.useEffect(() => {
    AsyncStorage.multiGet([ACCESS_TOKEN_KEY, USER_DATA_KEY, AUTHOR_DATA_KEY, AUTHOR_REGISTRATION_DATA_KEY]).then(data => {
      console.log('multiget');
      console.log(data);
      if (data.length !== 4) {
        return;
      }

      const accessTokenData = data[0];
      const accessToken = accessTokenData[1];
      if (typeof accessToken === 'string') {
        setAccessToken(accessToken);
      }

      const jsonUserData = data[1][1];
      const user = JSON.parse(jsonUserData ?? '{}');
      if (!_.isEmpty(user)) {
        setUser(user);
      }

      const jsonAuthorData = data[2][1];
      const author = JSON.parse(jsonAuthorData ?? '{}');
      if (!_.isEmpty(author)) {
        setAuthor(author);
      }

      const jsonAuthorRegData = data[3][1];
      const authorRegistration = JSON.parse(jsonAuthorRegData ?? '{}');
      if (!_.isEmpty(authorRegistration)) {
        setAuthorRegistration(authorRegistration);
      }
    });
  }, []);

  const [loginGraphql, {error: mutationError}] = useMutation<
    {login: UserLoginData},
    {data: UserLoginInput}
  >(LOGIN_USER);

  const [loginAppleGraphql] = useMutation<
    {loginApple: UserLoginData},
    UserAppleLoginInput
  >(LOGIN_APPLE);

  const [updateUserGraphql, {loading: updateUserGraphqlLoading}] = useMutation<
    {updateUser: UserData},
    {data: UserUpdateInput}
  >(UPDATE_USER);

  const [updateAuthorGraphql] = useMutation<
    {updateAuthor: Author},
    {data: AuthorUpdateInput}
    >(UPDATE_AUTHOR);

  const [setAuthorPaymentGraphql] = useMutation<
    {setAuthorPayment: Author},
    {data: AuthorPaymentInput}
    >(SET_AUTHOR_PAYMENT);

  const [registerAuthorGraphql] = useMutation(REGISTER_AUTHOR);

  const { refetch } = useQuery(FETCH_AUTHOR);

  const updateUser = async (data: UserUpdateInput) => {
    const responseData = await updateUserGraphql({
      variables: {
        data: data,
      },
    });

    if (!!responseData.data) {
      setUser({
        id: responseData.data.updateUser.id,
        isAdmin: responseData.data.updateUser.isAdmin,
        username: responseData.data.updateUser.username,
        name: responseData.data.updateUser.name,
        email: responseData.data.updateUser.email,
        profilePictureThumbnailUrl: responseData.data.updateUser.profilePictureThumbnailUrl,
        onboardingComplete: responseData.data.updateUser.onboardingComplete,
        isAuthor: responseData.data.updateUser.isAuthor,
        viewMature: responseData.data.updateUser.viewMature,
      });
    }
  };

  const updateAuthor = async (data: AuthorUpdateInput) => {
    const responseData = await updateAuthorGraphql({
      variables: {
        data: data,
      },
    });

    if (!!responseData.data) {
      setAuthor(constructAuthor(responseData.data.updateAuthor));
    }
  };

  const setAuthorPayment = async (data: AuthorPaymentInput) => {
    const responseData = await setAuthorPaymentGraphql({
      variables: {
        data: data,
      },
    });

    if (!!responseData.data) {
      setAuthor(constructAuthor(responseData.data.setAuthorPayment));
    }
  };

  React.useEffect(() => {
    if (mutationError !== undefined) {
      console.log(mutationError);
    }
  }, [mutationError]);

  const handleLoginResponse = (
    loginResponse: UserLoginData | null | undefined,
  ) => {
    if (!loginResponse) {
      throw new Error("We couldn't log you in. Try again!");
    }
    const accessToken = loginResponse.accessToken;
    setAccessToken(accessToken);
    AsyncStorage.setItem(ACCESS_TOKEN_KEY, accessToken)

    const user = {
      id: loginResponse.user.id,
      isAdmin: loginResponse.user.isAdmin,
      username: loginResponse.user.username,
      name: loginResponse.user.name,
      email: loginResponse.user.email,
      profilePictureThumbnailUrl: loginResponse.user.profilePictureThumbnailUrl,
      onboardingComplete: loginResponse.user.onboardingComplete,
      isAuthor: loginResponse.user.isAuthor,
      viewMature: loginResponse.user.viewMature,
    };
    setUser(user);
    AsyncStorage.setItem(USER_DATA_KEY, JSON.stringify(user));

    const author = loginResponse.user.connectedAuthor;
    setAuthor(constructAuthor(author));
    AsyncStorage.setItem(AUTHOR_DATA_KEY, JSON.stringify(author));

    const authorRegistration = loginResponse.user.authorRegistration;
    setAuthorRegistrationHelper(authorRegistration);
    AsyncStorage.setItem(AUTHOR_REGISTRATION_DATA_KEY, JSON.stringify(authorRegistration));
  };

  const login = async (username: string, password: string) => {
    console.log(username);
    console.log(password);

    try {
      const response = await loginGraphql({
        variables: {data: {email: username, password: password}},
      });
      console.log(response);
      handleLoginResponse(response.data?.login);
    } catch (ex) {
      console.log(ex);
      return false;
    }
    return true
  };

  const loginApple = async (identityToken: string) => {
    try {
      const response = await loginAppleGraphql({
        variables: {identityToken: identityToken, clientId: "com.stori.authorportal"},
      });
      console.log(response);
      handleLoginResponse(response.data?.loginApple);
    } catch (ex) {
      console.log(JSON.stringify(ex));
    }
  };

  const loginAsAuthor = async (id: string) => {
    console.log("login as author");
    const response = await refetch({id: id});
    console.log(response);
    if (response) {
      setAdminAuthor(constructAuthor(author));
      setAdminLoginAsAuthor(true);
      setAuthor(constructAuthor(response.data?.authorById));
    }
  };

  const [createUserGraphql, {loading: createUserInProgress}] = useMutation<
    {signupUser: CreateUserLoginData},
    {data: CreateUserInput}
  >(CREATE_USER);

  const createUser = async (
    email: string,
    username: string,
    password: string,
  ) => {
    try {
      const response = await createUserGraphql({
        variables: {
          data: {email: email, username: username, password: password},
        },
      });
      const responseData = response.data;
      if (!responseData) {
        throw new Error("We couldn't log you in. Try again!");
      }
      const accessToken = responseData.signupUser.accessToken;
      setAccessToken(accessToken);
      AsyncStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
      setUser({
        id: responseData.signupUser.user.id,
        isAdmin: responseData.signupUser.user.isAdmin,
        username: responseData.signupUser.user.username,
        name: responseData.signupUser.user.name,
        email: responseData.signupUser.user.email,
        profilePictureThumbnailUrl: responseData.signupUser.user.email,
        onboardingComplete: responseData.signupUser.user.onboardingComplete,
        isAuthor: responseData.signupUser.user.isAuthor,
        viewMature: responseData.signupUser.user.viewMature,
      });
      return CreateUserResult.SUCCESS;
    } catch (ex) {
      // TODO: this a slightly hacky way of determining sign up error
      if (ex.toString().includes('Unique constraint failed on the fields: (`email`)')) {
        return CreateUserResult.EMAIL_IN_USE;
      } else if (ex.toString().includes('Unique constraint failed on the fields: (`username`)')) {
        return CreateUserResult.USERNAME_IN_USE;
      } else {
        return CreateUserResult.OTHER_ERROR;
      }
    }
  };

  const registerAuthor = async (name: string, email: string, additionalInfo: string) => {
    try {
      const response = await registerAuthorGraphql({variables: {
        name: name,
        email: email,
        additionalInfo: additionalInfo
      }});
      console.log(response);
      setAuthorRegistrationHelper(response.data?.registerAuthor);
    } catch (ex) {
      console.log(JSON.stringify(ex));
    }
  };

  const setAuthorRegistrationHelper = (ar: AuthorRegistration | undefined) => {
    if (ar) {
      setAuthorRegistration({
        id: ar.id,
        userId: ar.userId,
        name: ar.name,
        email: ar.email,
        additionalInfo: ar.additionalInfo,
        status: ar.status,
      });
    }
  };

  const constructAuthor = (author: Author | undefined | null) => {
    if (author) {
      return{
        id: author.id,
        alias: author.alias,
        name: author.name,
        description: author.description,
        twitterHandle: author.twitterHandle,
        instagramHandle: author.instagramHandle,
        personalWebsite: author.personalWebsite,
        profilePicUrl: author.profilePicUrl,
        _legalName: author._legalName,
        _entityName: author._entityName,
        _ein: author._ein,
        _paypalEmail: author._paypalEmail,
        _country: author._country
      };
    }
    return null;
  };

  const logout = async () => {
    console.log("Logging out");
    await AsyncStorage.multiRemove([ACCESS_TOKEN_KEY, USER_DATA_KEY, AUTHOR_DATA_KEY, AUTHOR_REGISTRATION_DATA_KEY]);
    setAccessToken(undefined);
    setUser(null);
    setAdminAuthor(null);
    setAdminLoginAsAuthor(false);
    setAuthorRegistration(null);
    setAuthor(null);
  };

  const returnToAdmin = async () => {
    setAuthor(constructAuthor(adminAuthor));
    setAdminLoginAsAuthor(false);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        adminAuthor,
        adminLoginAsAuthor,
        author,
        authorRegistration,
        login,
        loginApple,
        loginAsAuthor,
        logout,
        returnToAdmin,
        createUser,
        registerAuthor,
        createUserInProgress,
        updateUser: updateUser,
        updateAuthor,
        setAuthorPayment,
        updateUserInProgress: updateUserGraphqlLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export {AuthContextProvider, useAuthContext};
