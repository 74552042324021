import React, {useState} from "react";
import '../App.css';
import LoginButton from "../components/LoginButton";
import SideBar from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useMutation, useQuery} from "@apollo/client";
// @ts-ignore
import Select from 'react-select';
import {SelectOption} from "../Types";
import AdminHeader from "../components/AdminHeader";

interface AuthorData {
  alias: string;
  name: string;
}

interface BookData {
  id: number;
  name: string;
  _royaltyRate: number;
  author: AuthorData;
}

interface FetchBookData {
  allBooks: Array<BookData>;
}

export const BOOK_QUERY = `
id
name
_royaltyRate
author {
  alias
  name
}
`;

export const ALL_BOOKS_FRAGMENT = `
allBooks {
  ${BOOK_QUERY}
}
`;

export const ALL_BOOKS = gql`
  query FetchBookRoyalties {
    ${ALL_BOOKS_FRAGMENT}
  }
`;

interface BookRoyaltyUpdateInput {
  id: number;
  royaltyRate: number;
}

const UPDATE_BOOK_ROYALTY = gql`
  mutation UpdateBook($data: BookRoyaltyUpdateInput!) {
    updateBookRoyalty(data: $data) {
      ${BOOK_QUERY}
    }
  }
`;

export function AdminRoyaltyPage () {
  const [bookDataMap, setBookDataMap] = useState<{[id: string] : BookData}>({});
  const [bookOptions, setBookOptions] = useState<SelectOption[]>([]);
  const [royaltyRate, setRoyaltyRate] = useState("");
  const [selectedBook, setSelectedBook] = useState<SelectOption | null>(null);
  const {data: bookData} = useQuery<FetchBookData>(ALL_BOOKS, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const [updateBookRoyaltyGraphql] = useMutation<
    {updateBookRoyalty: BookData},
    {data: BookRoyaltyUpdateInput}
    >(UPDATE_BOOK_ROYALTY);

  React.useEffect(() => {
    if (bookData) {
      console.log("book data");
      console.log(bookData);
      const options: SelectOption[] = [];
      const books: { [id: string] : BookData; } = {};
      for (let book of bookData.allBooks) {
        const author = book.author
        const label = book.name + " - " + author.alias + (author.name ? ` (${author.name})` : '');
        options.push({
          value: book.id.toString(),
          label: label,
        });
        books[book.id.toString()] = book;
      }
      setBookOptions(options);
      setBookDataMap(books);
    }
  }, [bookData]);

  function validateRoyaltyForm() {
    return !Number.isNaN(parseFloat(royaltyRate)) && Number(royaltyRate) >= 0 && Number(royaltyRate) <= 1;
  }

  function handleSelectBook(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelectedBook(selectVal);
    console.log(bookDataMap);
    console.log(selectVal.value);
    console.log(bookDataMap[selectVal.value]);
    setRoyaltyRate(bookDataMap[selectVal.value]._royaltyRate.toString());
    console.log(`Option selected:`, selectVal);
  };

  async function handleUpdateBookRoyalty() {
    console.log("handle update book royalty");
    updateBookRoyaltyGraphql({
      variables: {
        data: {
          id: Number(selectedBook!.value),
          royaltyRate: Number(royaltyRate)
        }
      },
    })
      .then(() => createToast("Book royalty updated."))
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      })
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Panel</h2>
          <hr/>
          <h5>
            Manage book royalties as an admin.
          </h5>
          <hr/>
          <h5><b>Book Royalties</b>:</h5>
          <Card>
            <Card.Header>Adjust Royalty</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleUpdateBookRoyalty();
              }}>
                {bookOptions && bookDataMap &&
                  <div>
                    <Form.Label>
                      Book
                    </Form.Label>
                    <Select
                      value={selectedBook}
                      onChange={handleSelectBook}
                      options={bookOptions}
                    />
                    <br></br>
                  </div>}
                <Form.Group style={{
                  marginBottom: '10px',
                  textAlign: 'left',
                }}>
                  <Form.Label>
                    Royalty Rate
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={royaltyRate}
                    rows={1}
                    type={"text"}
                    onChange={(e) => setRoyaltyRate(e.target.value)}
                    placeholder={"Royalty Rate"}
                  />
                </Form.Group>
                <LoginButton
                  text="Update"
                  type="submit"
                  disabled={!validateRoyaltyForm()}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
