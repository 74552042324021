import React from 'react';
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';
import ImageUploading, {ImageType, ResolutionType} from 'react-images-uploading';

const FormUpdateImage = ({
  fieldName,
  images,
  onChange,
  maxNumber=1,
  resolutionType=undefined,
  resolutionWidth=100,
  resolutionHeight=150,
  maxFileSize=6100000,
}: {
  fieldName: string;
  images: ImageType[];
  onChange: (imageList: ImageType[], addUpdateIndex: number[] | undefined) => void;
  maxNumber: number,
  resolutionType?: ResolutionType | undefined,
  resolutionWidth?: number,
  resolutionHeight?: number,
  maxFileSize?: number,
}) => {
  return (
    <Form.Group style={{
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: 'left',
    }}>
      <Form.Label>
        {fieldName}
      </Form.Label>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        resolutionType={resolutionType}
        resolutionWidth={resolutionWidth}
        resolutionHeight={resolutionHeight}
        maxFileSize={maxFileSize}
        dataURLKey="data_url"
      >
        {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageRemove,
            isDragging,
            errors,
          }) => (
          <div className="upload__image-wrapper">
            <Button
              onClick={() => {
                onImageRemoveAll();
                onImageUpload();
              }}
              style={{
                borderRadius: 8,
                padding: 0,
                fontWeight: 'bold',
                height: '30px',
                width: '20%',
                fontSize: '15px',
                backgroundColor: '#080404',
                marginBottom: '5px'
              }}
            >
              Upload Image
            </Button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <Button
                    onClick={() => onImageRemove(index)}
                    style={{
                      borderRadius: 8,
                      padding: 0,
                      fontWeight: 'bold',
                      height: '30px',
                      width: '20%',
                      fontSize: '15px',
                      backgroundColor: '#080404',
                      marginTop: '5px'
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
            {errors &&
              <div>
              {errors.acceptType && <span>Your selected file type is not allow</span>}
              {errors.maxFileSize && <span>Image size can not exceed 6MB</span>}
              {errors.resolution && <span>Image must have a width:height ratio of {resolutionWidth}:{resolutionHeight} (e.g. {100*resolutionWidth}x{100*resolutionHeight})</span>}
            </div>
            }
          </div>
        )}
      </ImageUploading>
    </Form.Group>
  );
};

export default FormUpdateImage;
