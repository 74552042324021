import React from "react";
import '../App.css';

export function NoMatchPage() {
  return (
    <div>
      <h4>404. The requested page doesn't exist.</h4>
    </div>
  );
}
