import React, {useState} from "react";
// @ts-ignore
import Select from 'react-select';
import '../App.css';
import Table from "react-bootstrap/Table";
import SideBar from "../components/SideBar";
import AdminHeader from "../components/AdminHeader";
import LoginButton from "../components/LoginButton";
import {FormDate} from "../components/FormDate";
import {SelectOption, BookCoupon, BookCouponData} from "../Types";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormInput from "../components/FormInput";
import {useAuthContext} from "../context/AuthContext";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";

interface BookData {
  id: string;
  name: string;
}

interface FetchBookData {
  allBooks: Array<BookData>;
}

export const FETCH_BOOKS = gql`
  query allBooks($authorId: Int!) {
    allBooks(authorId: $authorId) {
      id
      name
    }
  }
`;

interface FetchBookCouponData {
  bookCoupons: Array<BookCouponData>;
}

interface CreateCouponInput {
  bookId: number;
  numberOfCoupons: number;
  expiresAt: Date;
  chapterUnlockCount: number;
}

const COUPON_QUERY = `
id
expiresAt
remainingCoupons
couponsRedeemed
chapterUnlockCount
disabled
`;

export const FETCH_BOOK_COUPONS = gql`
  query FetchBookCoupons($authorId: Int!, $bookId: Int!) {
    bookCoupons(authorId: $authorId, bookId: $bookId) {
      ${COUPON_QUERY}
    }
  }
`;

const CREATE_COUPON = gql`
  mutation CreateCoupon($data: CreateCouponInput!) {
    createCoupon(data: $data) {
      ${COUPON_QUERY}
    }
  }
`;

const ENABLE_DISABLE_COUPON = gql`
  mutation EnableDisableCoupon($id: String!, $disabled: Boolean!) {
    enableDisableCoupon(id: $id, disabled: $disabled) {
      ${COUPON_QUERY}
    }
  }
`;

export function CouponsPage() {
  const [couponInfo, setCouponInfo] = useState<BookCouponData[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption[] | null>(null);
  const [selected, setSelected] = useState<SelectOption | null>(null);
  const [quantity, setQuantity] = useState<string>("");
  const [expiry, setExpiry] = useState<Date | null>(new Date());
  const [chaptersUnlocked, setChaptersUnlocked] = useState<string>("");
  const [showExpired, setShowExpired] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [fetchBooks, {data: bookData, error: bookError}] = useLazyQuery<FetchBookData>(FETCH_BOOKS);
  const [fetchBookCoupons, {data: bookCouponData, error: bookCouponError}] = useLazyQuery<FetchBookCouponData>(FETCH_BOOK_COUPONS, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const [createCouponGraphql] = useMutation<
    {createCoupon: BookCoupon},
    {data: CreateCouponInput}
    >(CREATE_COUPON);
  const [enableDisableCouponGraphql] = useMutation<
    {enableDisableCoupon: BookCoupon},
    {id: String, disabled: Boolean}
    >(ENABLE_DISABLE_COUPON);
  const {author} = useAuthContext();

  React.useEffect(() => {
    if (author) {
      fetchBooks({
        variables: {authorId: author.id},
      });
    }
  }, [author, fetchBooks]);

  React.useEffect(() => {
    if (selected && author) {
      console.log("fetching coupons");
      fetchBookCoupons({
        variables: {authorId: author.id, bookId: Number(selected.value)},
      });
    }
  }, [author, selected, fetchBookCoupons]);


  React.useEffect(() => {
    if (bookData) {
      console.log('------------received book data');
      console.log(bookData);
      const bookOptions: SelectOption[] = [];
      for (let book of bookData.allBooks) {
        console.log(book);
        bookOptions.push({
          value: book.id.toString(),
          label: book.name,
        });
      }
      setSelectOptions(bookOptions);
    }
    if (bookError) {
      console.log(bookError);
    }
  }, [bookData, bookError]);

  React.useEffect(() => {
    if (bookCouponData) {
      console.log('------------received book coupon data');
      console.log(bookCouponData);
      const currentTime: Date = new Date();
      setCouponInfo(bookCouponData.bookCoupons.filter((o) => {
        return (showExpired || new Date(o.expiresAt) > currentTime) && (showDisabled || !o.disabled);
      }));
    }
    if (bookCouponError) {
      console.log(bookCouponError);
    }
  }, [bookCouponData, bookCouponError, showExpired, showDisabled]);

  function selectBook(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setSelected(selectVal);
    console.log(`Option selected:`, selectVal);
  };

  async function couponEnableDisable(i: number) {
    const responseData = await enableDisableCouponGraphql({
      variables: {
        id: couponInfo[i].id,
        disabled: !couponInfo[i].disabled,
      },
    }).then(() => {
      createToast("Coupon deleted.");
      if (author) {
        fetchBookCoupons({
          variables: {authorId: author.id, bookId: Number(selected!.value)},
        });
      }
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function handleCreateCoupon() {
    const responseData = await createCouponGraphql({
      variables: {
        data: {
          bookId: Number(selected!.value),
          numberOfCoupons: Number(quantity),
          expiresAt: expiry!,
          chapterUnlockCount: Number(chaptersUnlocked),
        },
      },
    }).then(() => {
      createToast("Coupon created.");
      if (author) {
        fetchBookCoupons({
          variables: {authorId: author.id, bookId: Number(selected!.value)},
        });
      }
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  function isNumber(value: string): boolean {
    return !Number.isNaN(parseInt(value)) && Number(value) >= 0;
  }

  function validateCreateCoupon() {
    return isNumber(quantity) && isNumber(chaptersUnlocked);
  }

  function renderRows() {
    const currentTime: Date = new Date();
    return couponInfo.filter((o) => {
      return (showExpired || new Date(o.expiresAt) > currentTime) && (showDisabled || !o.disabled);
    }).map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.id}
          </td>
          <td>
            {o.chapterUnlockCount}
          </td>
          <td>
            {o.couponsRedeemed}
          </td>
          <td>
            {o.remainingCoupons}
          </td>
          <td>
            {o.expiresAt.toString().substring(0, 10)}
          </td>
          <td>
            <LoginButton
              text={o.disabled ? "Enable" : "Disable"}
              type="button"
              disabled={false}
              onClick={() => couponEnableDisable(i)}
            />
          </td>
        </tr>
      );
    });
  }

  function handleCheckExpired(e: any) {
    setShowExpired(e.target.checked);
  }

  function handleCheckDisabled(e: any) {
    setShowDisabled(e.target.checked);
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Coupons</h2>
          <hr/>
          <h5>Manage your coupons here.</h5>
          {!selected &&
          <p>Please select a book to manage coupons for.</p>
          }
          {selectOptions &&
          <div>
            <h5><b>Book</b>:</h5>
            <Select
              value={selected}
              onChange={selectBook}
              options={selectOptions}
            />
          </div>}
          {selected &&
            <div>
              <br></br>
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th>Coupon Code</th>
                  <th>Chapter Unlocks</th>
                  <th># Redeemed</th>
                  <th># Remaining</th>
                  <th>Expires</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                  {couponInfo && renderRows()}
                </tbody>
              </Table>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Show expired coupons"
                  onChange={handleCheckExpired}
                />
                <Form.Check
                  type="checkbox"
                  label="Show disabled coupons"
                  onChange={handleCheckDisabled}
                />
              </Form.Group>
              <hr/>
              <Card className="CreateCoupon">
                <Card.Header>Create New Coupon</Card.Header>
                <Card.Body>
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    // handleUpdate();
                  }}>
                    <FormInput
                      fieldName="Quantity"
                      value={quantity}
                      type="text"
                      onChangeText={(e) => setQuantity(e.target.value)}
                    />
                    <FormInput
                      fieldName="Chapters Unlocked Per Coupon"
                      value={chaptersUnlocked}
                      type="text"
                      onChangeText={(e) => setChaptersUnlocked(e.target.value)}
                    />
                    <FormDate
                      label={"Expiry Date"}
                      value={expiry}
                      minDate={new Date()}
                      onChange={(date: Date | null) => {
                        if (date === null || date instanceof Date) {
                          setExpiry(date);
                        }
                      }}
                    />
                    <LoginButton
                      text="Create Coupon"
                      type="button"
                      disabled={!validateCreateCoupon()}
                      onClick={handleCreateCoupon}
                    />
                  </Form>
                </Card.Body>
              </Card>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
