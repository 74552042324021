import React, {useState} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import WebToastContainer from "../components/WebToastContainer";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import AdminHeader from "../components/AdminHeader";
import PERIOD_OPTIONS, {
  FETCH_BOOK_VIEWS,
  FETCH_CHAPTER_READS,
  FETCH_REVENUE,
  MetricOverTime
} from './Analytics';
// @ts-ignore
import Select from 'react-select';

export const APP_STATS_FRAGMENT = `
appStats {
  numBooks
  numSeasons
  numAuthors
  numUsers
}
`;

export const FETCH_NEW_USERS = gql`
  query FetchNewUsers($dateFrom: DateTime, $dateTo: DateTime, $groupBy: String!) {
    newUsers(dateFrom: $dateFrom, dateTo: $dateTo, groupBy: $groupBy) {
      sum
      doy
      week
      month
      year
    }
  }
`;

interface AppStats {
  numBooks: number;
  numSeasons: number;
  numAuthors: number;
  numUsers: number;
}

interface FetchAppStats {
  appStats: AppStats;
}

interface MetricAggregation {
  sum: number;
  doy?: number;
  week?: number;
  month?: number;
  year?: number;
}

export interface RevenueData {
  revenue: Array<MetricAggregation>;
}
export interface BookViewData {
  bookViews: Array<MetricAggregation>;
}
export interface ChapterReadData {
  chapterReads: Array<MetricAggregation>;
}
export interface NewUsersData {
  newUsers: Array<MetricAggregation>;
}

export const APP_STATS = gql`
  query FetchAppStats {
    ${APP_STATS_FRAGMENT}
  }
`;

interface SelectOption {
  value: string;
  label: string;
}
const GRAPH_PERIODS = 7;

// TODO: use dropdown instead of email field for easier author selection
export function AdminStatsPage () {
  const {data: appStatsData} = useQuery<FetchAppStats>(APP_STATS);
  const [periodSelect, setPeriodSelect] = useState<SelectOption>({
    value: "AllTime",
    label: "All Time",
  });
  const [fetchRevChartData, {data:revChartData, error:revChartError}] = useLazyQuery<RevenueData>(FETCH_REVENUE);
  const [fetchBVChartData, {data:bvChartData, error:bvChartError}] = useLazyQuery<BookViewData>(FETCH_BOOK_VIEWS);
  const [fetchCRChartData, {data:crChartData, error:crChartError}] = useLazyQuery<ChapterReadData>(FETCH_CHAPTER_READS);
  const [fetchNUChartData, {data:nuChartData, error:nuChartError}] = useLazyQuery<NewUsersData>(FETCH_NEW_USERS);
  const [revenueHistory, setRevenueHistory] = useState<Array<MetricAggregation> | null>(null);
  const [bookViewHistory, setBookViewHistory] = useState<Array<MetricAggregation> | null>(null);
  const [chapterReadHistory, setChapterReadHistory] = useState<Array<MetricAggregation> | null>(null);
  const [newUsersHistory, setNewUsersHistory] = useState<Array<MetricAggregation> | null>(null);

  function handlePeriodChange(selectVal: SelectOption | null) {
    if (!selectVal) {
      return;
    }
    setPeriodSelect(selectVal);
    console.log(`Period option selected:`, selectVal);
  };

  React.useEffect(() => {
    if (appStatsData) {
      console.log(appStatsData);
      console.log(appStatsData.appStats.numUsers);
    }
  }, [appStatsData]);

  React.useEffect(() => {
    if (revChartData) {
      console.log("Revenue Chart fetched = ");
      console.log(revChartData!.revenue);
      setRevenueHistory(revChartData!.revenue);
    }
    if (revChartError) {
      console.log(revChartError);
    }
  }, [revChartData, revChartError]);

  React.useEffect(() => {
    if (bvChartData) {
      console.log("BV Chart fetched = ");
      console.log(bvChartData!.bookViews);
      setBookViewHistory(bvChartData!.bookViews);
    }
    if (bvChartError) {
      console.log(bvChartError);
    }
  }, [bvChartData, bvChartError]);

  React.useEffect(() => {
    if (crChartData) {
      console.log("CR Chart fetched = ");
      console.log(crChartData!.chapterReads);
      setChapterReadHistory(crChartData!.chapterReads);
    }
    if (crChartError) {
      console.log(crChartError);
    }
  }, [crChartData, crChartError]);

  React.useEffect(() => {
    if (nuChartData) {
      console.log("NU Chart fetched = ");
      console.log(nuChartData!.newUsers);
      setNewUsersHistory(nuChartData!.newUsers);
    }
    if (nuChartError) {
      console.log(nuChartError);
    }
  }, [nuChartData, nuChartError]);

  React.useEffect(() => {
    function fetchMetrics(period: string) {
      let dateTo: Date | undefined = new Date();
      let dateFrom: Date | undefined = new Date();
      let groupBy : string | undefined;

      // adjust date be 1-2 days earlier because we don't have real time amplitude data
      // need to wait for amplitude to aggregate data, and also for our script to fetch amplitude data
      dateTo.setUTCHours(23);

      if (period === "Doy") {
        console.log("Change to day");
        dateFrom.setDate(dateTo.getDate() - 1 * GRAPH_PERIODS);
        groupBy = "doy";
      } else if (period === "Week") {
        dateFrom.setDate(dateTo.getDate() - 7 * GRAPH_PERIODS);
        groupBy = "week";
      } else if (period === "Month") {
        dateFrom.setDate(dateTo.getDate() - 30 * GRAPH_PERIODS);
        groupBy = "month";
      } else if (period === "AllTime") {
        dateFrom = new Date(2021, 0, 1);
        groupBy = "month";
      }
      console.log("Fetch " + dateFrom);
      setRevenueHistory(null);
      setBookViewHistory((null));
      setChapterReadHistory((null));
      fetchRevChartData({
        variables: {
          id: undefined,
          bookId: undefined,
          chapterId: undefined,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
      fetchBVChartData({
        variables: {
          id: undefined,
          bookId: undefined,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
      fetchCRChartData({
        variables: {
          id: undefined,
          bookId: undefined,
          chapterId: undefined,
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
      fetchNUChartData({
        variables: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          groupBy: groupBy
        },
      });
    };

    // Load metric data on select change
    if (periodSelect) {
      fetchMetrics(periodSelect.value);
    }
  }, [periodSelect, fetchRevChartData, fetchBVChartData, fetchCRChartData, fetchNUChartData]);

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Admin Stats</h2>
          <hr/>
          <h5>
            View stats for Stori.
          </h5>
          <hr/>
          {appStatsData &&
            <div>
              <h5><b>Number of Books</b>: {appStatsData.appStats.numBooks}</h5>
              <h5><b>Number of Seasons</b>: {appStatsData.appStats.numSeasons}</h5>
              <h5><b>Number of Authors</b>: {appStatsData.appStats.numAuthors}</h5>
              <h5><b>Number of Users</b>: {appStatsData.appStats.numUsers}</h5>
              <hr></hr>
              <div>
                <p><b>Period</b>:</p>
                <Select
                  value={periodSelect}
                  onChange={handlePeriodChange}
                  options={PERIOD_OPTIONS}
                />
              </div>
              {periodSelect && revenueHistory && bookViewHistory && chapterReadHistory && newUsersHistory &&
                <Metrics
                  periodSelect={periodSelect}
                  revenueHistory={revenueHistory}
                  bookViewHistory={bookViewHistory}
                  chapterReadHistory={chapterReadHistory}
                  newUsersHistory={newUsersHistory}
                />
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const Metrics = ({
                   periodSelect,
                   revenueHistory,
                   bookViewHistory,
                   chapterReadHistory,
                   newUsersHistory,
                 }: {
  periodSelect: SelectOption,
  revenueHistory: Array<MetricAggregation>,
  bookViewHistory: Array<MetricAggregation>,
  chapterReadHistory: Array<MetricAggregation>
  newUsersHistory: Array<MetricAggregation>
}) => {
  function periodMetricSum(metrics: Array<MetricAggregation>, periodSelect: SelectOption) {
    if (periodSelect.value === "AllTime") {
      return metrics.reduce(function(prev, cur) {return prev + cur.sum;}, 0);
    } else {
      return metrics[metrics.length - 1].sum;;
    }
  }
  const periodRevenue = periodMetricSum(revenueHistory, periodSelect);
  const periodBookViews = periodMetricSum(bookViewHistory, periodSelect);
  const periodChapterReads = periodMetricSum(chapterReadHistory, periodSelect);;
  const periodNewUsers = periodMetricSum(newUsersHistory, periodSelect);;

  return (
    <div>
      <div>
        <br/>
        <div>
          <p><b>Revenue</b>: {(periodRevenue / 100).toFixed(2)}$</p>
          <MetricOverTime
            chartData={revenueHistory}
            metricName={"Revenue"}
            dataMultiplier={0.01}
          />
        </div>
        <p><b>Book Views</b>: {periodBookViews}</p>
        <MetricOverTime
          chartData={bookViewHistory}
          metricName={"Book Views"}
        />
        <p><b>Chapter Reads</b>: {periodChapterReads}</p>
        <MetricOverTime
          chartData={chapterReadHistory}
          metricName={"Chapter Reads"}
        />
        <p><b>New Users</b>: {periodNewUsers}</p>
        <MetricOverTime
          chartData={newUsersHistory}
          metricName={"New Users"}
        />
      </div>
    </div>
  );
};
