import React from 'react';
import Form from "react-bootstrap/Form";
// @ts-ignore
import Select from 'react-select';
import {SelectOption} from '../Types';

const FormSelect = ({
  fieldName,
  options,
  onChange,
  value,
  multiSelect=false,
  isClearable=false,
}: {
  fieldName: string,
  options: SelectOption[],
  onChange: ((selectVals: SelectOption[]) => void) | ((selectVal: SelectOption) => void);
  value: SelectOption[] | SelectOption | null,
  multiSelect?: boolean,
  isClearable?: boolean,
}) => {
  return (
    <Form.Group style={{
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: 'left',
    }}>
      <Form.Label>
        {fieldName}
      </Form.Label>
      {multiSelect ? (
        <Select
          value={value}
          isMulti
          options={options}
          onChange={onChange}
          isClearable={isClearable}
          className="basic-select"
          classNamePrefix="select"
        />
      ) : (
        <Select
          value={value}
          options={options}
          onChange={onChange}
          isClearable={isClearable}
          className="basic-select"
          classNamePrefix="select"
        />
      )}
    </Form.Group>
  );
};

export default FormSelect;
