import React, {useState} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import WebToastContainer from "../components/WebToastContainer";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormInput from "../components/FormInput";
import {FormCountry, COUNTRIES_MAP} from "../components/FormCountry";
import LoginButton from "../components/LoginButton";
import {createToast} from "../components/WebToast";
import {Country} from '../Types';
import '../../node_modules/react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import {useAuthContext} from "../context/AuthContext";
import AdminHeader from "../components/AdminHeader";


export function PaymentPage() {
  const [legalName, setLegalName] = useState("");
  const [entityName, setEntityName] = useState("");
  const [ein, setEin] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [country, setCountry] = useState<Country | null>(null);
  const [checked, setChecked] = useState(false);
  const {author, setAuthorPayment} = useAuthContext();

  React.useEffect(() => {
    if (author) {
      if (author._legalName) {
        setLegalName(author._legalName);
      }
      if (author._entityName) {
        setEntityName(author._entityName);
      }
      if (author._ein) {
        setEin(author._ein);
      }
      if (author._paypalEmail) {
        setPaypalEmail(author._paypalEmail);
      }
      if (author._country) {
        setCountry(COUNTRIES_MAP.get(author._country) ?? null);
      }
    }
  }, [author]);

  async function handleUpdate() {
    console.log("handle update");
    if (!paypalEmail.includes("@")) {
      createToast("Please enter a valid email with '@'.");
      return;
    }
    if (!author || !country || !legalName.length || !ein.length) {
      createToast("Name, EIN, and country are mandatory fields.");
      return;
    }
    console.log(country);
    setAuthorPayment({
      id: author.id,
      legalName: legalName,
      entityName: entityName,
      ein: ein,
      paypalEmail: paypalEmail,
      country: country.name,
    })
      .then(() => createToast("Payment information updated."))
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      })
  }

  function validateForm() {
    return checked && paypalEmail.length > 0
      && country && country.name.length > 0;
  }

  function handleCheck(e: any) {
    console.log(e.target.checked);
    setChecked(e.target.checked);
  }

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Payment</h2>
          <hr/>
          <h5>Payment information can be submitted or edited here.</h5>
          <h5>Money is paid out every quarter to the Paypal address you provide to us.</h5>
          <Card className="PaymentInfo">
            <Card.Header>Payment Information</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleUpdate();
              }}>
                <FormInput
                  fieldName="Legal Name"
                  value={legalName}
                  type="text"
                  onChangeText={(e) => setLegalName(e.target.value)}
                />
                <FormInput
                  fieldName="Entity Name"
                  value={entityName}
                  type="text"
                  onChangeText={(e) => setEntityName(e.target.value)}
                />
                <FormInput
                  fieldName="EIN (Taxpayer ID / SSN)"
                  value={ein}
                  type="text"
                  onChangeText={(e) => setEin(e.target.value)}
                />
                <FormInput
                  fieldName="Paypal Email"
                  value={paypalEmail}
                  type="email"
                  onChangeText={(e) => setPaypalEmail(e.target.value)}
                />
                <FormCountry
                  value={country}
                  onChange={setCountry}
                />
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="I have read and understand the author portal terms of service."
                    onChange={handleCheck}
                  />
                </Form.Group>
                <Form.Label>
                  {"* We are not responsible for any losses incurred from submitting an incorrect email."}
                </Form.Label>
                <LoginButton
                  text="Update"
                  type="submit"
                  disabled={!validateForm()}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
