import React, {useState} from "react";
import '../App.css';
import SideBar from "../components/SideBar";
import {useAuthContext} from "../context/AuthContext";
import WebToastContainer from "../components/WebToastContainer";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormInput from "../components/FormInput";
import LoginButton from "../components/LoginButton";
import AdminHeader from "../components/AdminHeader";
import {createToast} from "../components/WebToast";
import {ImageType} from "react-images-uploading";
import FormUploadImage from "../components/FormUploadImage";

export function HomePage() {
  const [name, setName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [alias, setAlias] = useState("");
  const [description, setDescription] = useState("")
  const [twitterHandle, setTwitterHandle] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [personalWebsite, setPersonalWebsite] = useState("");
  const [images, setImages] = useState<Array<ImageType>>([]);
  const {author, updateAuthor} = useAuthContext();

  React.useEffect(() => {
    if (author && author.name) {
      setName(author.name);
    }
  }, [author]);

  React.useEffect(() => {
    if (author) {
      if (author.alias) {
        setAlias(author.alias);
      }
      if (author.name) {
        setAuthorName(author.name);
      }
      if (author.twitterHandle) {
        setTwitterHandle(author.twitterHandle);
      }
      if (author.instagramHandle) {
        setInstagramHandle(author.instagramHandle);
      }
      if (author.personalWebsite) {
        setPersonalWebsite(author.personalWebsite);
      }
      if (author.description) {
        setDescription(author.description);
      }
    }
  }, [author]);

  async function handleUpdate() {
    if (!author) {
      return;
    }
    console.log("handle update");
    updateAuthor({
      id: author.id,
      alias: alias,
      name: authorName,
      twitterHandle: twitterHandle,
      instagramHandle: instagramHandle,
      personalWebsite: personalWebsite,
      description: description,
      file: images.length === 1 ? images[0].file : null,
    })
      .then(() => createToast("Author profile updated."))
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      })
  }

  const onUploadCover = (imageList: ImageType[], addUpdateIndex: number[] | undefined) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Home</h2>
          <hr/>
          <h5>Welcome {name}! Edit your author profile here.</h5>
          <h5>Payment information can be submitted or updated on the Payment page.</h5>
          <Card className="AuthorProfile">
            <Card.Header>Author Profile</Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => {
                e.preventDefault();
                handleUpdate();
              }}>
                { author &&
                  <div>
                    {/*eslint-disable-next-line*/}
                    <img
                      src={(images.length === 1) ? images[0].data_url : author.profilePicUrl}
                      alt={"No profile picture available"}
                      height={"30%"}
                      width={"30%"}
                      className="center"
                    />
                  </div>
                }
                <FormInput
                  fieldName="Alias"
                  value={alias}
                  type="text"
                  onChangeText={(e) => setAlias(e.target.value)}
                />
                <FormInput
                  fieldName="Name"
                  value={authorName}
                  type="text"
                  onChangeText={(e) => setAuthorName(e.target.value)}
                />
                <FormInput
                  fieldName="Twitter Handle"
                  value={twitterHandle}
                  type="text"
                  onChangeText={(e) => setTwitterHandle(e.target.value)}
                />
                <FormInput
                  fieldName="Instagram Handle"
                  value={instagramHandle}
                  type="text"
                  onChangeText={(e) => setInstagramHandle(e.target.value)}
                />
                <FormInput
                  fieldName="Personal Website"
                  value={personalWebsite}
                  type="text"
                  onChangeText={(e) => setPersonalWebsite(e.target.value)}
                />
                <FormInput
                  fieldName="Description"
                  value={description}
                  type="text"
                  rows={3}
                  onChangeText={(e) => setDescription(e.target.value)}
                />
                <p> Note: To bold or italicize text, wrap in double and single asterisks.<br/>
                  (i.e. **bold text**, *italicized text*)
                </p>
                <FormUploadImage
                  fieldName="Profile Picture"
                  images={images}
                  onChange={onUploadCover}
                  maxNumber={1}
                  // resolutionType={"ratio"}
                  // resolutionWidth={1}
                  // resolutionHeight={1}
                />
                <p>* Picture will be resized to 300x300.</p>
                <LoginButton
                  text="Update"
                  type="submit"
                  disabled={false}
                />
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
