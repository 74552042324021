import React, {ChangeEvent} from 'react';
import Form from "react-bootstrap/Form";

const LoginInput = ({
  placeholder,
  onChangeText,
  type,
}: {
  placeholder?: string;
  onChangeText: (event:  ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  type: string,
}) => {
  return (
    <Form.Group style={{
      marginTop: '5px',
      marginBottom: '5px',
    }}>
      <Form.Control
        type={type}
        onChange={onChangeText}
        placeholder={placeholder}
      />
    </Form.Group>
  );
};

export default LoginInput;
