import React from 'react';
import { Button } from 'react-bootstrap';

// TODO: rename; this is used for more than just login buttons
const LoginButton = ({
  text,
  type,
  disabled,
  onClick,
}: {
  text: string;
  type: string;
  disabled: boolean;
  onClick?: () => void;
}) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        borderRadius: 8,
        padding: 0,
        fontWeight: 'bold',
        width: '100%',
        height: '40px',
        backgroundColor: '#080404',
        marginTop: '10px',
        marginBottom: '10px'
      }}
    >
      {text}
    </Button>
  );
};

export default LoginButton;
