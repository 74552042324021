import React from 'react';
import {Menu, MenuItem, SubMenu, ProSidebar, SidebarHeader} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {useAuthContext} from "../context/AuthContext";

// TODO: sidebar should always span entire vertical length
const SideBar = () => {
  const {user, author} = useAuthContext();

  return (
    <ProSidebar style={{
      height: '100vh',
      position: 'fixed'
    }}>
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <img className="Logo" src = "./stori-script.png"  alt={"Stori"}/>
          Author Portal
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        { user && user.isAdmin &&
          <div>
            <SubMenu title="Admin" defaultOpen={true}>
              <MenuItem><Link to="/admin-login" /> Login</MenuItem>
              <MenuItem><Link to="/admin-royalty" /> Royalty</MenuItem>
              <MenuItem><Link to="/admin-carousel" /> Carousel</MenuItem>
              <MenuItem><Link to="/admin-genre" /> Genre</MenuItem>
              <MenuItem><Link to="/admin-coupon" /> Coupon</MenuItem>
              <MenuItem><Link to="/admin-stats" /> Stats</MenuItem>
              <MenuItem><Link to="/admin-users" /> Users</MenuItem>
              {/*<MenuItem><Link to="/analytics" /> Analytics</MenuItem>*/}
              <MenuItem><Link to="/coupons" /> Author Coupons</MenuItem>
            </SubMenu>
          </div>
        }
        { user && !author &&
          <MenuItem><Link to="/register-author" /> Register Author</MenuItem>
        }
        { user && author &&
          <div>
            <MenuItem><Link to="/home"/> Home</MenuItem>
            <MenuItem><Link to="/payment" /> Payment</MenuItem>
            <MenuItem><Link to="/content" /> Manage Content</MenuItem>
            <MenuItem><Link to="/analytics" /> Analytics</MenuItem>
            {/*<MenuItem><Link to="/coupons" /> Coupons</MenuItem>*/}
            <MenuItem><Link to="/bonus" /> Bonus Content</MenuItem>
          </div>
        }
        <MenuItem><Link to="/logout" /> Log Out </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default SideBar;
