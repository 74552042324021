import React from 'react';
import {ToastContainer} from "react-toastify";

const WebToastContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={1}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
    />
  );
};

export default WebToastContainer;
