import React, {useState} from "react";
import '../App.css';
import Table from "react-bootstrap/Table";
import SideBar from "../components/SideBar";
import AdminHeader from "../components/AdminHeader";
import LoginButton from "../components/LoginButton";
import {SelectOption, BonusContent} from "../Types";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormInput from "../components/FormInput";
import {useAuthContext} from "../context/AuthContext";
import {createToast} from "../components/WebToast";
import WebToastContainer from "../components/WebToastContainer";
import FormSelect from "../components/FormSelect";

interface FetchContentData {
  bonusContent: Array<BonusContent>;
}

interface CreateBonusContentInput {
  authorId: number;
  typeId: number;
  link: string;
  additionalInfo?: string;
}

const CONTENT_QUERY = `
id
type {
  id
  name
}
link
additionalInfo
`;

export const FETCH_CONTENT = gql`
  query FetchBonusContent($authorId: Int!) {
    bonusContent(authorId: $authorId) {
      ${CONTENT_QUERY}
    }
  }
`;

const CREATE_CONTENT = gql`
  mutation CreateBonusContent($data: CreateBonusContentInput!) {
    createBonusContent(data: $data) {
      ${CONTENT_QUERY}
    }
  }
`;

const DELETE_CONTENT = gql`
  mutation DeleteBonusContent($id: Int!) {
    deleteBonusContent(id: $id) {
      ${CONTENT_QUERY}
    }
  }
`;

interface BonusContentType {
  id: number;
  name: string;
}

interface FetchBonusContentTypesData {
  bonusContentTypes: Array<BonusContentType>;
}

export const ALL_BONUS_CONTENT_TYPES = gql`
  query FetchBonusContentTypes {
    bonusContentTypes {
      id
      name   
    }
  }
`;

export function BonusContentPage() {
  const [bonusContent, setBonusContent] = useState<BonusContent[]>([]);
  const [link, setLink] = useState<string>("");
  const [additionalInfo, setAdditionInfo] = useState<string>("");
  const [fetchBonusContent, {data: bonusContentData, error: bonusContentError}] = useLazyQuery<FetchContentData>(FETCH_CONTENT, {
    fetchPolicy: "network-only" // Doesn't check cache before making a network request
  });
  const {data: bonusContentTypeData} = useQuery<FetchBonusContentTypesData>(ALL_BONUS_CONTENT_TYPES);
  const [createContentGraphql] = useMutation<
    {createBonusContent: BonusContent},
    {data: CreateBonusContentInput}
    >(CREATE_CONTENT);
  const [deleteContentGraphql] = useMutation<
    {deleteContent: BonusContent},
    {id: number}
    >(DELETE_CONTENT);
  const {author} = useAuthContext();
  const [contentTypes, setContentTypes] = useState<SelectOption[]>([]);
  const [selectedType, setSelectedType] = useState<SelectOption | null>(null);

  React.useEffect(() => {
    console.log(bonusContentTypeData);
    if (bonusContentTypeData) {
      console.log("received bonus content types")
      console.log(bonusContentTypeData);
      console.log(bonusContentTypeData.bonusContentTypes);
      setContentTypes(bonusContentTypeData.bonusContentTypes.map(ct => ({
        value: ct.id.toString(),
        label: ct.name,
      })))
    }
  }, [bonusContentTypeData]);

  React.useEffect(() => {
    if (author) {
      console.log("fetching content");
      fetchBonusContent({
        variables: {authorId: author.id},
      });
    }
  }, [author, fetchBonusContent]);

  React.useEffect(() => {
    if (bonusContentData) {
      console.log('------------received bonus content data');
      console.log(bonusContentData);
      setBonusContent(bonusContentData.bonusContent);
    }
    if (bonusContentError) {
      console.log(bonusContentError);
    }
  }, [bonusContentData, bonusContentError]);

  async function contentDelete(i: number) {
    const responseData = await deleteContentGraphql({
      variables: {
        id: bonusContent[i].id,
      },
    }).then(() => {
      createToast("Bonus content deleted.");
      if (author) {
        fetchBonusContent({
          variables: {authorId: author.id},
        });
      }
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  async function handleCreateContent() {
    if (author === null || author === undefined) {
      return;
    }
    const responseData = await createContentGraphql({
      variables: {
        data: {
          authorId: author.id,
          typeId: Number(selectedType!.value),
          link: link,
          additionalInfo: additionalInfo,
        },
      },
    }).then(() => {
      createToast("Content created.");
      if (author) {
        fetchBonusContent({
          variables: {authorId: author.id},
        });
      }
    })
      .catch(error => {
        createToast(error.message);
        console.log(error.message);
      });
    console.log(responseData);
  }

  function validateCreateContent() {
    console.log(selectedType);
    return link != null && link.length > 0 && selectedType != null;
  }

  function renderRows() {
    return bonusContent.map((o, i) => {
      return (
        <tr key={"item-" + i}>
          <td>
            {o.type.name}
          </td>
          <td>
            {o.link}
          </td>
          <td>
            {o.additionalInfo}
          </td>
          <td>
            <LoginButton
              text="Delete"
              type="button"
              disabled={false}
              onClick={() => contentDelete(i)}
            />
          </td>
        </tr>
      );
    });
  }

  function handleContentTypeSelect(selectVal: SelectOption) {
    console.log('Option selected:', selectVal);
    setSelectedType(selectVal);
  };

  return (
    <div>
      <WebToastContainer/>
      <div className="DashboardContainer">
        <div className="DashboardSideBar">
          <SideBar/>
        </div>
        <div className="DashboardContent">
          <AdminHeader/>
          <h2>Bonus Content</h2>
          <hr/>
          <h5>Manage your bonus content here.</h5>
          <div>
            <br></br>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Type</th>
                <th>Link</th>
                <th>Additional Information</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {bonusContent && renderRows()}
              </tbody>
            </Table>
            <hr/>
            <Card className="BonusContent">
              <Card.Header>Add Bonus Content</Card.Header>
              <Card.Body>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  // handleUpdate();
                }}>
                  <FormSelect
                    fieldName={"Content Type"}
                    options={contentTypes}
                    onChange={handleContentTypeSelect}
                    value={selectedType}
                    multiSelect={false}
                  />
                  <FormInput
                    fieldName="Link"
                    value={link}
                    type="text"
                    onChangeText={(e) => setLink(e.target.value)}
                  />
                  <FormInput
                    fieldName="Additional Information"
                    rows={3}
                    value={additionalInfo}
                    type="text"
                    onChangeText={(e) => setAdditionInfo(e.target.value)}
                  />
                  <LoginButton
                    text="Add"
                    type="button"
                    disabled={!validateCreateContent()}
                    onClick={handleCreateContent}
                  />
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
