import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import '../App.css';
import LoginInput from "../components/LoginInput";
import LoginButton from "../components/LoginButton";
import FormTextButton from "../components/FormTextButton";
import AppleLoginButton from "../components/AppleLoginButton";
import WebToastContainer from "../components/WebToastContainer";
import {useHistory, useLocation} from "react-router-dom";
import {useAuthContext} from "../context/AuthContext";
import {createToast} from "../components/WebToast";

export function LoginPage() {
  const history = useHistory();
  const { state } = useLocation();
  const { from }: any = state || { from: { pathname: "/home" } };
  const {user, login, loginApple} = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  React.useEffect(() => {
    if (user) {
      console.log(user);
      history.push(from);
    }
  }, [user, from, history]);

  // Listen for apple authorization success
  document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
    console.log('success');
    console.log(data);
    loginApple(data.detail.authorization.id_token);
    console.log(user);
  });

  // Listen for apple authorization failures
  document.addEventListener('AppleIDSignInOnFailure', (error) => {
    console.log('fail');
    console.log(error);
  });

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function toCreateAccount() {
    history.push('/create-account');
  }

  function toResetPassword() {
    history.push('/reset-password');
  }

  return (
    <div>
      <WebToastContainer/>
      <Card className="Login">
        <Card.Header>Sign in to Author Portal</Card.Header>
        <Card.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            login(email, password).then(success => {
              if (!success) {
                createToast(`Invalid email or password.`);
              }
            })
          }}>
            <AppleLoginButton />
            <LoginInput
              placeholder="Email"
              type="email"
              onChangeText={(e) => setEmail(e.target.value)}
            />
            <LoginInput
              placeholder="Password"
              type="password"
              onChangeText={(e) => setPassword(e.target.value)}
            />
            <LoginButton
              text="Login"
              type="submit"
              disabled={!validateForm()}
            />
            <FormTextButton onClick={toCreateAccount} text={"Create Account"}/>
            <br/>
            <FormTextButton onClick={toResetPassword} text={"Reset Password"}/>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
