import React from 'react';
import Button from "react-bootstrap/Button";

const FormTextButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <Button style={{
      textDecoration: "none",
      color: "#282c34",
    }}
      onClick={onClick}
      variant={"link"}
      type={"reset"}
      size={"sm"}
    >
      {text}
    </Button>
  );
};

export default FormTextButton;
